import { IconographyProps } from '@facile-it/full-metal-ui/components'
import { useIconSvgClassName } from '@facile-it/full-metal-ui/hooks'
export const ArrowRightIcon: React.FC<IconographyProps> = p => {
    const cl = useIconSvgClassName(p)

    return (
        <svg className={cl} width="21" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m7.792 14.5 1.41-1.41-4.58-4.59h16.17v-2H4.622l4.59-4.59L7.792.5l-7 7 7 7Z" fill="currentColor" />
        </svg>
    )
}
