import * as React from 'react'

export const ChillingPeople: React.FC<{ className?: string }> = ({ className }) => (
    <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 622 374"
        width="100%"
        height="100%"
        role="img"
    >
        <title id="who">Chi Siamo</title>
        <path
            d="M81.01 70.4s-8.5-6.44-17-5.3c-8.51 1.15-14.95 10.35-15.87 25.75-.92 15.4 4.83 25.3-.69 34.48-5.52 9.2-25.51 16.56-34.71 26.67-9.2 10.11-24.6 34.94 9.43 62.07 0 0 16.55 11.72 36.54-.23 0 0-22.31-45.98-11.72-59.77 10.6-13.8 23.92-16.78 23.92-16.78l7.35-33.8-4.6-1.83-1.14-4.6 4.14-3.22 5.07 3.68-.23 5.98 1.84-.24s5.28-6.2 4.83-14.7a33.9 33.9 0 0 0 20.46-2.76s5.29 8.96 4.36 18.4c0 0-6.43 30.1-25.28 23.2v12.42s29.2 7.61 38.85 23.91c0 0 9.42-12.41.69-26.88 0 0 5.51-25.28-8.74-38.62 0 0 17.24-21.83-4.83-35.17-.02-.04-18.64-16.35-32.67 7.33Z"
            fill="#FFD05A"
        />
        <path
            d="M108.73 85.78s-7.24 6.12-19.84 2.03c0 0-2.35 16.23-6.5 15.95 0 0-1.66-1.3-.36-5.75 0 0-1.49-3.52-4.46-3.7-2.96-.19-5.35 2.69-4.63 5.01 0 0 .56 3.34 5.2 4.64l-6.31 32.63s-16.5 6.12-20.96 12.8c-4.44 6.68-11.5 5.74-2.4 38.57l20.57 50.07c.24.55-.54 2.5-.65 3.08a85.35 85.35 0 0 0-1.11 8.18c-.7 7.77-.46 15.84 1.82 23.35a31.12 31.12 0 0 0 12.62 17.14c4.36 3 10.65 3.62 15.88 4.17 6.14.63 12.33.63 18.5.61 18.87-.05 37.78-.58 56.6.39 0 0-10.94 28.37 4.06 59.33a15.13 15.13 0 0 0 11.66-.92s13.54-32.64 15.4-50.26l27.85 25.23s7.97-.37 8.71-8.35c0 0-26.7-62.67-39.86-73.61 0 0-17.43-17.8-58.6-3.53l-17.43-6.67 1.11-8.72 12.42-4.82 36.72-23.92s5.2-6.12-1.3-11.86h-12.6s-24.3-10.74-33.39-22.44c0 0-15.76-18.92-39.86-24.67l-.37-12.42s12.05 4.82 20.77-8.34c8.71-13.17 4.26-30.79.74-33.2Z"
            fill="#fff"
        />
        <path
            d="m166.35 209.61 19.08-4.6L218.99 151l83.45-.23-32.88 58.85H166.35Zm-7.97.39H621v10H142l16.39-10Zm-140.3 1.68s19.54 14.94 40.69 2.53l9.88 24.14S56.48 285 90.26 293.29c0 0 46.2 1.84 52.41 1.38 0 0 5.52 5.97-2.99 10.8 0 0-63.67 8.97-97.93-12.87 0 0-30.1-16.08-23.67-80.92Z"
            fill="#fff"
        />
        <path d="m462.43 151.68-97.47-.46 23.45 58.85h97.24l-23.22-58.39Z" fill="#fff" />
        <path
            d="M394.83 151.03s22.9-23.89 40.27-25.27c0 0 52.3-2.37 76.18 30 0 0 18.35 17.96 15.39 53.88H485.8l-90.98-58.61Z"
            fill="#fff"
        />
        <path
            d="M439.04 93.59s-7.1 17.57-5.13 26.05c1.97 8.5 7.1 28.4 33.15 18.56 0 0 8.88-2.57 12.04-29.42 0 0 .8-7.1.6-8.48 0 0-14.41 2.17-19.34-16.97l-10.47 2.17s-.98 10.27-10.85 8.09Z"
            fill="#fff"
        />
        <path
            d="M436.08 99.57s-12.68-31.03 20.47-35.91c0 0 12.01-.13 19.54 5.01 0 0 22.7-4.09 29.57 7.27 0 0 6.09 21.39-10.96 23.36 0 0 0 7-3.55 8.45 0 0 .66 6.47-5.8 8.59 0 0-1.2-3.44-6.21-.27l.2-7.6-3.76-4.28-2.03-4.17-14.14-16.9-9.84 3.4-4.06 6.56-6.67 1.02-2.76 5.47Z"
            fill="#FFD05A"
        />
        <path
            d="M436.34 101.29s-2.37-3.96-3.55-3.96c-1.17 0-2.77.66-2.77 2.77 0 2.1-.4 6.08 2.91 8.45l1.85.79 1.56-8.05Zm41.6 14.65s4.22-2.77 5.8-1.19c0 0 2.38 2.9.8 5.42 0 0-4.76 5.14-7.66 4.09l1.05-8.32Zm46.44 104.76-111.52-.3-6.79 28.91s-32.15 4.14-37.47 18.59l-18.26 91.47h39.55l23.33-89.4-18.36 88.51 38.65.6 24.48-87.04s50.16-2.51 66.4-51.34Z"
            fill="#fff"
        />
        <path d="M429.78 359.13v13.3h-6.8v-4.62l-5.2 4.66h-44.03v-3.58s17.89.94 24.54-9.67" fill="#F5F2D8" />
        <path d="M386.18 359.13v13.3h-6.8v-4.62l-5.18 4.64h-44.05v-3.58s17.89.94 24.54-9.67" fill="#F5F2D8" />
        <path
            d="M499.2 143.18s34.42 23.78 27.47 66.46h20.98s-4.84-45.38-48.45-66.46Zm25.18 77.52s-9.26 44.26-66.38 51.34l-4.4 15.62s103.17 11.66 95.76-66.97l-24.98.01Z"
            fill="#fff"
        />
        <path
            d="M188.44 353.47s9.1 12.91 20.79 14.87c0 0 3.66.97 3.17 3.78h-30.6s-10.48-1.24-5.36-17.33m63.85-33.19s12.07 2.03 19.83-3.31c0 0 2.63-1.47 3.9.4l-17.47 16.94s-7.32 4.72-13.3-7.3"
            fill="#FFF6DB"
        />
        <path
            d="m172.88 186.8 9.56.5 8.82 2.94 2.2 1.77-8.03 13-7.4.9-3.16-1.7s-2.42-2.35-2.28-2.64l1.18-2.28 2.2-2.65-.29-4.33-.88-3.16-1.92-2.35Z"
            fill="#fff"
        />
        <path
            d="M95.17 100.88c.08.02.21.1.3.35.1.25.12.6.01 1-.1.4-.3.69-.51.85-.21.17-.36.18-.44.16-.08-.03-.2-.1-.3-.36-.1-.25-.12-.6-.01-1 .1-.39.31-.68.52-.85.2-.17.36-.18.43-.15Zm12.59 3.83c.08.02.2.1.3.36.09.25.11.6 0 1-.1.39-.3.68-.51.85-.2.17-.36.17-.44.15-.08-.02-.2-.1-.3-.35-.09-.25-.11-.6 0-1 .1-.4.3-.69.5-.85.22-.17.37-.18.45-.16Z"
            fill="#0A0340"
            stroke="#0A0340"
            strokeWidth="1.5"
        />
        <path
            d="M100.88 108.16s4.56 3.7-.47 5.87m1.7 4.44s-6.24.8-10.65-5.3m231.67-15.86a48.27 48.27 0 1 0 0-96.55 48.27 48.27 0 0 0 0 96.55Z"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m334.96 37.2-11.83 11.83-11.6-33.77m-6.46 171.77a20.78 20.78 0 0 0-.21 2.88 20.52 20.52 0 0 0 41.04 0 20.78 20.78 0 0 0-.2-2.88h-40.63Z"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M346.83 190.98a5.58 5.58 0 0 1 5.56 5A5.58 5.58 0 0 1 348 202a5.57 5.57 0 0 1-5.03-1.45M479.4 108.7s-1.57 38.44-27.9 31.92c-26.31-6.52-15.71-39.56-12.58-46.3"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M436.24 100.1s-5.84-18.07 1.2-26.58c9.1-10.98 24.18-12.87 39.63-4.8"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M471.75 71.8s6.51-6.17 23.83-2.9c18.76 3.54 11.66 31.2 0 30.87"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M493.87 97.37s3.1 6.34-2.74 10.65M442.4 84.85s7.9 3.42 21.27-3.61m-3 1.84s3.26 20.93 20.58 16.82m-2.52 16.55s5.83-4.46 6.34.17c.52 4.63-3.6 8.24-7.89 7.72m-40.8-22.96s-3.56-6.43-5.89-2.4c-2.33 4.04 0 9 4.12 10.25M485.08 116.62s7.25-1.07 5.2-10.14m-4.06 103.96-23.45-59.11h-97.34m23.2 58.76-23.27-58.76m29.45-.25s19.78-21.3 40.35-25.2m38.63 52.81s16.4-1.57 31.36 5.68m-92.34 88.04-22.54 86.88h-39.92l18-90.68s4.52-13.99 37.44-18.52l7.3-29.54M458.05 271.9l-23.75 87.28H394.4l19.22-90.57m41 18.73s95.54 9.76 95.64-58.82m-.38-7.82c.2 2.53.33 5.13.38 7.8m-75.81-97.1s62.88 18 74.04 78.88"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M466.56 116.78c.16.07.34.25.42.61.09.37.06.85-.14 1.35-.2.5-.53.87-.84 1.07-.32.2-.57.2-.73.14-.17-.07-.35-.25-.43-.61-.09-.37-.06-.85.15-1.35.2-.5.52-.87.83-1.07.32-.2.57-.2.74-.14Zm-20.6-7.33c.16.07.34.25.43.61.08.37.05.85-.15 1.35-.2.5-.52.87-.84 1.07-.32.2-.57.2-.73.14-.17-.07-.34-.25-.43-.62-.08-.36-.06-.84.15-1.34.2-.5.52-.87.84-1.07.31-.2.57-.2.73-.14Z"
            fill="#0A0340"
            stroke="#0A0340"
            strokeWidth="1.5"
        />
        <path
            d="M444.57 127.16s4.7 6.28 13.95 4.9m-7.38-10.15s-2.09 3.47 2.77 3.93m70.77 94.63c-8.54 28.31-34.6 49.4-66.07 51.52m15.3-140.78c31.02 8.62 53.75 36.28 53.75 69.07a70.2 70.2 0 0 1-.72 10.07M374.42 372.7h-44.05v-3.57s17.89.94 24.54-9.67m31.49-.1v8.13"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M429.87 359.37v13.3h-6.8v-4.63l-5.2 4.66h-44.02v-3.57s17.88.94 24.54-9.67m51.4-273.42s-1.98 10.33-12.17 7.58M81.91 122.08s5.53 9.08 15 5.92c7.1-2.37 16.65-12.62 16.32-26.75a35.37 35.37 0 0 0-4.06-15.28"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M111.37 83.47s-7.88 9.3-22.84 4.35c0 0-1.43 15.12-5.65 16.13-2.95.71-.12-7.06-.12-7.06m-.67 1.67s-3.38-5.89-6.52-4.22c-3.15 1.68-4.57 8.37 3.2 9.44m.69-2.89-1.53-2.94m.35 1.88-1.88.24m2.39 3.7-7.1 33.04s1.76 6 15.77 5.07v-14.37"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M71.7 136.79s-24.27 9.2-27.07 23.33m81.54 67.45-1.49 8.62c-30.03 10.02-55.7 1.9-55.7 1.9L55.9 205.47c-14-28.27-11.27-45.35-11.27-45.35"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M49.2 151.53s13.08-13.66 34.62 9.3c18.73 19.96 32.03 31.62 32.03 31.62"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M111.73 193.28s47-6.83 61.47-6.36c0 0 7.66 7.54-.58 13.66-8.25 6.13-32.65 20.6-32.65 20.6-17.9 10.49-27.79 8.12-40.86.36-11.95-7.11-52.96-44.18-52.96-44.18"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M87.7 139.93s21.81 4.71 37.57 22.26c14.76 16.4 31.1 23.67 36.98 25.09m10.95-.36s12.02-.5 19.84 5.02"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m164.5 210.32 21.07-5.5 34.03-53.94h83.14l-32.77 58.68m-201 28.53s-15 59 36.82 56.76c43.6-1.88 51.89-15.6 82.92-20.87"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m204.5 303.13 27.26 25.2s7.52.18 8.84-7.9c0 0-22.75-56.22-39.67-73.54-24.82-25.35-95.9 10.34-95.9 10.34m5.66 37.44h62.23"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M188.72 273.98s26.7-.77 10.34 49.43l-10.53 30.27s-7.15 3.95-12.03.57c0 0-15.43-34.6-2.45-60.73"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M188.53 353.7s9.1 12.92 20.8 14.87c0 0 3.65.98 3.16 3.78h-30.6s-10.48-1.24-5.35-17.33m63.84-33.18s12.07 2.03 19.83-3.31c0 0 2.63-1.47 3.9.4l-17.47 16.94s-7.32 4.72-13.3-7.3M106.08 161.92a13.07 13.07 0 0 1 8.7 12.49 13.21 13.21 0 0 1-6.25 11.35m-49.56 28.56c-5.21 5.21-37.4 13.75-53.6-20.1-16.18-33.84 18.43-51.54 34.06-60.28 7.18-4.02 10.14-11.69 10.05-19.62-.04-3.55-.9-6.84-1.35-10.32-.78-5.88-.25-11.88.73-17.7 1.12-6.6 3.16-14.35 8.65-18.7 7.25-5.73 16.89-1.45 23.66 2.9 0 0 16.52-29.56 40.32.62 8.43 10.7-2.47 26.54-2.47 26.54"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.4 211.9s-8.76 70.9 33.12 86.16c41.89 15.26 88.57 7.07 88.57 7.07s7.07-1.5 3.35-9.87"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.21 238.51c2.8-6.7 8.53-10.2 14.4-10.06 10.54.24 19.98 11.04 23.03 20.32.76 2.3 1.66 4.57 2.57 6.8 5.71 14.12 17.08 36.98 34.8 38.35m-8.35 11.88v55.59m0 .68s-14.17 11.64-22.5 11.04h29.68"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M84.66 362.07s14.16 11.64 22.5 11.06H77.5m395.12-85.1v73.36m0 .68s-14.16 11.64-22.5 11.04h29.68"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M472.61 362.07s14.16 11.64 22.5 11.06h-29.67M117.15 97.46s15.02 8.22 10.29 39.09"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M120.46 130.79s18.32 9.88 7 33.12M159.69 210H621v10H142m-45.11.2H74.96v-9.88H84m74.25 10.52V373m358.29-152.16V373M124.68 236.2s7.04 2.7 16.88 7.02m31.06-42.63s.74 8.2 12.95 4.24m288.1-104.54s2.09 9.68 9.84 8.7M405.79 250.1s73.48 16.9 105.23-4.83M81.65 64.19c-.98 1.32-2 3.21-.5 6.31"
            stroke="#0A0340"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
