import { Desktop } from './Desktop'
import { Mobile } from './Mobile'
import * as React from 'react'
import { Hidden } from '@facile-it/full-metal-ui/components'

export const Header = () => (
    <>
        <Hidden above="tablet">
            <Mobile />
        </Hidden>
        <Hidden below="tablet">
            <Desktop />
        </Hidden>
    </>
)
