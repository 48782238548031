import { IconographyProps } from '@facile-it/full-metal-ui/components'
import { useIconSvgClassName } from '@facile-it/full-metal-ui/hooks'
export const UserIcon: React.FC<IconographyProps> = p => {
    const cl = useIconSvgClassName(p)

    return (
        <svg className={cl} viewBox="1.5 2 15 15" width="15" height="15" xmlns="http://www.w3.org/2000/svg">
            <title>User</title>
            <path
                d="M9 2C4.86 2 1.5 5.36 1.5 9.5 1.5 13.64 4.86 17 9 17c4.14 0 7.5-3.36 7.5-7.5C16.5 5.36 13.14 2 9 2ZM5.303 14.21c.322-.675 2.287-1.335 3.697-1.335 1.41 0 3.383.66 3.697 1.335A5.92 5.92 0 0 1 9 15.5a5.92 5.92 0 0 1-3.697-1.29Zm8.467-1.088c-1.072-1.304-3.675-1.747-4.77-1.747-1.095 0-3.697.443-4.77 1.748A5.962 5.962 0 0 1 3 9.5c0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6a5.962 5.962 0 0 1-1.23 3.623ZM9 5a2.618 2.618 0 0 0-2.625 2.625A2.618 2.618 0 0 0 9 10.25a2.618 2.618 0 0 0 2.625-2.625A2.618 2.618 0 0 0 9 5Zm0 3.75a1.123 1.123 0 0 1-1.125-1.125C7.875 7.003 8.377 6.5 9 6.5s1.125.503 1.125 1.125c0 .623-.502 1.125-1.125 1.125Z"
                fill="currentColor"
            />
        </svg>
    )
}
