import * as React from 'react'
import { ArrowLeftIcon } from './arrow-left'
import { ArrowRightIcon } from './arrow-right'
import { MenuIcon } from './menu-icon'
import { MenuCloseIcon } from './menuclose-icon'
import { UserIcon } from './user-icon'
import carIcon from './car-icon.svg'
import coinsIcon from './coins-icon.svg'
import doctorIcon from './doctor-icon.svg'
import downloadIcon from './download-icon.svg'
import envelopeIcon from './envelope-icon.svg'
import fileIcon from './file-icon.svg'
import pdfIcon from './pdf-icon.svg'
import phoneIcon from './phone-icon.svg'
import renewcheckIcon from './renewcheck-icon.svg'
import scalesIcon from './scales-icon.svg'
import securityIcon from './security-icon.svg'
import thumbupIcon from './thumbup-icon.svg'
import towtruckIcon from './towtruck-icon.svg'

export type IconVariant =
    | 'arrowLeft'
    | 'arrowRight'
    | 'car'
    | 'coins'
    | 'doctor'
    | 'download'
    | 'envelope'
    | 'file'
    | 'menu'
    | 'menuclose'
    | 'pdf'
    | 'phone'
    | 'renewcheck'
    | 'scales'
    | 'security'
    | 'thumbup'
    | 'towtruck'
    | 'user'
    | 'userMobile'

export const getIconImgSrc = (variant: IconVariant | undefined) => {
    switch (variant) {
        case 'car':
            return carIcon
        case 'coins':
            return coinsIcon
        case 'doctor':
            return doctorIcon
        case 'download':
            return downloadIcon
        case 'file':
            return fileIcon
        case 'envelope':
            return envelopeIcon
        case 'pdf':
            return pdfIcon
        case 'phone':
            return phoneIcon
        case 'renewcheck':
            return renewcheckIcon
        case 'scales':
            return scalesIcon
        case 'security':
            return securityIcon
        case 'thumbup':
            return thumbupIcon
        case 'towtruck':
            return towtruckIcon
    }
}

export const getIconSrc = (variant: IconVariant) => {
    switch (variant) {
        case 'arrowLeft':
            return React.createElement(ArrowLeftIcon, { scale: 1.5 })
        case 'arrowRight':
            return React.createElement(ArrowRightIcon, { scale: 1.5 })
        case 'user':
            return React.createElement(UserIcon, { scale: 1 })
        case 'userMobile':
            return React.createElement(UserIcon, { scale: 1.5 })
        case 'menu':
            return React.createElement(MenuIcon, { scale: 2 })
        case 'menuclose':
            return React.createElement(MenuCloseIcon, { scale: 2 })
    }
}
