import * as React from 'react'

export const ManWithCar: React.FC<{ className?: string }> = ({ className }) => (
    <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 617 356"
        width="100%"
        height="100%"
        role="img"
    >
        <title id="saturno">Saturno homepage</title>
        <path
            d="M310.56 75.4a24.63 24.63 0 0 0-11.44-5.18c-13.47-2.3-26.21 9.87-28.52 23.34l136.82-.07a20.71 20.71 0 0 0-30.45-21.6"
            fill="#fff"
        />
        <path
            d="M310.54 75.44a34 34 0 0 1 66.2-3.7m117.9 70.46a18.9 18.9 0 0 0-8.78-3.97c-10.34-1.77-20.11 7.56-21.88 17.9l104.99-.05a15.89 15.89 0 0 0-14.79-18.55 15.89 15.89 0 0 0-8.58 1.97"
            fill="#fff"
        />
        <path d="M494.62 142.23a26.08 26.08 0 0 1 24.27-21.7 26.09 26.09 0 0 1 26.54 18.86" fill="#fff" />
        <path
            d="M552.86 322.88h-8.9a30.9 30.9 0 0 0-.06 1.84c0 15.86 12.34 28.73 27.56 28.73 15.23 0 27.57-12.85 27.57-28.73 0-.66 0-1.32-.07-1.97l-8.06.02v4.46h-37.85s-.45-4.35-.2-4.35Zm-185.41 0h-8.9a30.9 30.9 0 0 0-.06 1.84c0 15.86 12.34 28.73 27.57 28.73 15.22 0 27.56-12.85 27.56-28.73 0-.66-.02-1.32-.06-1.97l-8.07.02-38.04.1Z"
            fill="#FFF6DB"
        />
        <path
            d="M606.82 308.81c5.07 0 9.18-7.34 9.18-16.4 0-9.06-4.11-16.4-9.18-16.4s-9.19 7.34-9.19 16.4c0 9.06 4.12 16.4 9.19 16.4Zm-79.53 0c5.07 0 9.18-7.34 9.18-16.4 0-9.06-4.11-16.4-9.18-16.4-5.07 0-9.18 7.34-9.18 16.4 0 9.06 4.1 16.4 9.18 16.4Z"
            fill="#FFD05A"
        />
        <path
            d="m338.69 254.35-3.52-13.84a6.69 6.69 0 0 1 4.83-8.1 6.68 6.68 0 0 1 8.1 4.83l4.38 17.28"
            fill="#F5F2D8"
        />
        <path
            d="m352.48 254.51-4.38-17.28a6.68 6.68 0 0 0-8.1-4.82 6.68 6.68 0 0 0-4.82 8.1l3.5 13.84"
            fill="#FFF6DB"
        />
        <path
            d="m286.44 254.35-3.51-13.84a6.68 6.68 0 0 1 4.82-8.1 6.69 6.69 0 0 1 8.1 4.83l4.38 17.28"
            fill="#F5F2D8"
        />
        <path
            d="m286.44 254.35-3.51-13.84a6.68 6.68 0 0 1 4.82-8.1 6.69 6.69 0 0 1 8.1 4.83l4.38 17.28"
            fill="#F5F2D8"
        />
        <path
            d="M255.19 277.9h9.83a6.48 6.48 0 0 0 4.58-1.9 6.48 6.48 0 0 0 1.9-4.57V255h-16.31v22.9Z"
            fill="#FFD05A"
        />
        <path
            d="m613.94 303.6-7.85-28.13c-12.06-.47-19.92 5.16-19.92 5.16l-61.51-26.13h-4.57l-13.47-30.93H453.9l-.14.62h-46.64l9.65 30.69h-5.95v-9.12a1.72 1.72 0 0 0-1.72-1.71h-24.5a1.72 1.72 0 0 0-1.72 1.71v9.12h-29.64v.06h-98.05v68.1h26.7l-.23 1.06.59 6.56 1.29 4.1 2.57 5.26 3.75 4.92 4.58 3.88 6.07 2.93 6.48 1.75 10.66.12 8.67-1.64 6.07-2.46 6.45-3.75 4.22-3.97 4-6.1 2.8-6.45 1.28-6.32 86.82.46-.23 3.05 1.05 6.8 2.11 4.68 5.04 7.15 6.07 4.69 8.55 3.04 5.63.7 6.67.24 9.14-1.4 6.92-2.23 8.09-5.51 5.06-5.63s3.64-7.86 4.33-8.9c.7-1.06 1.4-7.04 1.4-7.04l45.08-.16.19 4.38h37.85v-4.47l23.16-.1-.13-19.09Z"
            fill="#fff"
        />
        <path
            d="M606.67 309.07c5.06 0 9.15-7.32 9.15-16.35s-4.1-16.35-9.15-16.35c-5.05 0-9.14 7.32-9.14 16.35s4.1 16.35 9.15 16.35Z"
            fill="#FFE79F"
        />
        <path
            d="M527.45 309.07c5.05 0 9.14-7.32 9.14-16.35s-4.1-16.35-9.14-16.35c-5.06 0-9.15 7.32-9.15 16.35s4.1 16.35 9.15 16.35Z"
            fill="#FED05D"
        />
        <path
            d="m338.69 254.35-3.52-13.84a6.68 6.68 0 0 1 4.83-8.1v0a6.68 6.68 0 0 1 8.09 4.83l4.39 17.28m238.59 68.38H614V303m-115.48 19.9h54.29m-25.52-14.08c5.07 0 9.18-7.35 9.18-16.41s-4.11-16.4-9.18-16.4c-5.07 0-9.18 7.34-9.18 16.4 0 9.06 4.1 16.4 9.18 16.4Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M526.34 308.81c-6.15 0-11.14-7.34-11.14-16.4 0-9.06 4.99-16.4 11.14-16.4m80.48 32.8c5.07 0 9.18-7.34 9.18-16.4 0-9.06-4.11-16.4-9.18-16.4s-9.19 7.34-9.19 16.4c0 9.06 4.12 16.4 9.19 16.4Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M605.86 308.81c-6.15 0-11.14-7.34-11.14-16.4 0-9.06 5-16.4 11.14-16.4"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M586.18 280.52a30.13 30.13 0 0 1 10.52-4.16 28.33 28.33 0 0 1 10.12-.34m-82.36-21.46 65.44 27.58m1 31.56h-37.83v13.68h37.84V313.7ZM271.49 255v16.45a6.47 6.47 0 0 1-6.48 6.47h-9.81m86.83 44.97h95.8"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M255.16 255.04v67.86h26.52m131.07-67.86 88.74 29.08c3.07-3.57 8.45-6.55 14.92-7.87a30.97 30.97 0 0 1 11.55-.26m-160.89-1.14h-14.8v3.62h14.8v-3.62Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M385.1 322.9h-17.45a22.07 22.07 0 0 1-22-22v-45.87m190.82 37.38h58.25m-70.26-37.85-113.42.38m-72.36-.59-3.5-13.84a6.68 6.68 0 0 1 4.82-8.1v0a6.68 6.68 0 0 1 8.09 4.83l4.39 17.28m-66.04-.17-3.51-13.84a6.68 6.68 0 0 1 4.82-8.1 6.68 6.68 0 0 1 8.1 4.83l4.37 17.28"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m286.44 254.35-3.51-13.84a6.68 6.68 0 0 1 4.82-8.1 6.69 6.69 0 0 1 8.1 4.83l4.37 17.28"
            fill="#FFF6DB"
        />
        <path
            d="m286.44 254.35-3.51-13.84a6.68 6.68 0 0 1 4.82-8.1 6.68 6.68 0 0 1 8.1 4.83l4.37 17.28m219.74.06-13.2-30.78h-99.61l10.1 30.8"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M606.82 308.81c5.07 0 9.18-7.34 9.18-16.4 0-9.06-4.11-16.4-9.18-16.4s-9.19 7.34-9.19 16.4c0 9.06 4.12 16.4 9.19 16.4Z"
            fill="#FED05D"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M527.29 308.81c5.07 0 9.18-7.34 9.18-16.4 0-9.06-4.11-16.4-9.18-16.4-5.07 0-9.18 7.34-9.18 16.4 0 9.06 4.1 16.4 9.18 16.4Zm-145-54.25-127.13.48m54.38 98.42c39.84 1.5 41.55-30.35 41.55-30.35"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M309.54 353.46c15.23 0 27.57-12.86 27.57-28.72s-12.34-28.72-27.57-28.72c-15.22 0-27.56 12.86-27.56 28.72s12.34 28.72 27.56 28.72Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M342.02 322.67s-3.03-36.45-34.8-35.47c-29.84.92-34 29.83-32.87 35.69h7.33"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M465.8 334.03c5.16 0 9.35-4.82 9.35-10.76 0-5.95-4.19-10.77-9.35-10.77-5.17 0-9.36 4.82-9.36 10.77 0 5.94 4.19 10.76 9.36 10.76Z"
            fill="#FFF6DB"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M464.69 353.54c40.6 2.91 42.9-30.67 42.9-30.67"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M465.7 353.46c15.21 0 27.56-12.86 27.56-28.72s-12.35-28.72-27.57-28.72-27.56 12.86-27.56 28.72 12.34 28.72 27.56 28.72Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M498.31 322.93s-3.18-36.71-34.95-35.73c-29.83.92-34.02 29.83-32.86 35.69h7.32m106.13.01a30.9 30.9 0 0 0-.06 1.84c0 15.86 12.35 28.74 27.57 28.74 15.23 0 27.57-12.86 27.57-28.74 0-.66 0-1.02-.07-1.67m-231.51-.2h-8.9a30.9 30.9 0 0 0-.06 1.85c0 15.86 12.34 28.73 27.57 28.73 15.22 0 27.56-12.85 27.56-28.73 0-.66-.02-1.32-.06-1.97l-8.07.02-38.04.1Zm42.21-60.34h-25.09a2.08 2.08 0 0 1-2.06-2.08v-14.41a2.08 2.08 0 0 1 2.08-2.08h24.24a2.08 2.08 0 0 1 2.08 2.08v11.67"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M417.22 261.54c-.26-.06-4.17-2.43-6.44-3.81l-1.58-.97m-2.41 4.55c.3.24 8.69 3.43 8.69 3.43"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M309.65 334.03c5.17 0 9.35-4.82 9.35-10.76 0-5.95-4.18-10.77-9.35-10.77s-9.36 4.82-9.36 10.77c0 5.94 4.19 10.76 9.36 10.76Z"
            fill="#FFF6DB"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m494.51 223.9-23.65 30.57m-8.13-30.49-23.89 30.68m2.5-30.85-23.86 30.65m84.9-30.65-23.56 30.93"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M158.99 330.12v24.3h9.9v-8.43l7.52 8.46h64.18v-6.52s-26.06 1.72-35.73-17.65l-45.87-.16Zm-122.25.16c-9.69 19.37-35.73 17.65-35.73 17.65v6.54h64.16l7.52-8.46v8.4h9.92v-24.3l-45.87.17Z"
            fill="#FFF6DB"
        />
        <path
            d="M89.23 84.58c.98.5 2.32 2.32 3.87 1.45 2.25-1.26 2.61-3.56-1.8-13.72-4.4-10.15-8.12-13.73 1.1-19.02 9.23-5.28 23.46-6.81 26.74-3.75 3.55 3.31 5.96 12.45 5.96 12.45s14.91.23 13.3-14.1c0 0 13.33-5.32 10.55-18.91-2.77-13.6-12.97-15.19-12.97-15.19.8.1 1.4-13.16-9.8-13.76-10.87-.57-22.82 7.19-27.82 17.26-6.72 13.54-11.88 13.01-14.3 13.2-2.43.2-17.11-1.38-19.72 16.22-3.06-1.83-10.57-5.04-14.43-3.17-3.6 1.76 4.83 5.05 10.5 6.38a49.83 49.83 0 0 0-4.88-.05 9.84 9.84 0 0 0-2.96.42 2.62 2.62 0 0 0-1.1.62c-.3.47.2.94.57 1.14 1.06.6 2.26.4 3.42.37 1.04-.05 2.46-.22 3.5-.35-2.56 2.63-4.92 6.53-4.1 11.61 1.66 10.08 18 18.55 19.28 22.82 1.52-1.94 4.13-4.1 6.44-4.94 1.9-.7 4.94 1.15 6.89 1.79.69.23 1.1.89 1.76 1.23Z"
            fill="#FED05D"
        />
        <path
            d="m158.53 202.81 49.04 126.98h-50.5l-45.74-105.03 9.4 21.85-36.2 83.18h-50.5l48.85-125.83s44.27 7.99 75.66-1.15Z"
            fill="#fff"
        />
        <path
            d="M216.3 156.45c-4.28-30.76-45.44-50.88-94.44-47.65l1.53 6.59-11.55 10.7-16.48-9.28-1.48-3.68c-45.48 11.24-77.17 40.82-73.03 70.52 1.83 13.13 10.38 24.33 23.38 32.6l2.43-3.6 4.55 3.96-1.06 3.18 2.52 4.1 4.93 2.16 4.63-1.73 8.06-8.75 5.04-11.13 1.2-2.12 4.1.94s23.46 3.84 23.86 3.84h30.1l22.4-3.71 5.83-1.46 1.59-.53 6.23 7.82 5.57 4.37 4.9-.13 2.78-1.88 1.88-2.12-.26-1.72-.4-2.92 4.23-4.1 2.43 3.66c17.31-13.56 26.93-30.72 24.53-47.93Z"
            fill="#fff"
        />
        <path
            d="m74.12 86.5 1.2 3.86 4.22 2.97 5.67-.37 1.88-.35 8.27 24.1 16.1 10.06s12.16-10.56 12.05-10.97c-.12-.4-1.97-11.5-1.97-11.5l6.28-5.49L131.24 88l-.99-9.08-3.69-13.4-4.23-9.8-3.2-6.2-9.4-.9-12.49 2.07-7.1 3.32-3.06 3.51-.36 2.79 2.35 6.2 4.86 12.59.47 5.21-1.28 1.7-2.95-.53-3.9-3.12-4.76-.72-3.6 1.08-3.14 3.14-.65.63Z"
            fill="#fff"
        />
        <path
            d="M93.14 96.23s7.07 19.18 27.11 8.51c16.02-8.53 10.9-29.2 5.3-42.33"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M99.16 67.93c.14.61.08 1.17-.08 1.57-.17.41-.41.6-.64.66-.23.05-.53-.01-.86-.3a2.63 2.63 0 0 1-.77-1.38c-.14-.6-.09-1.16.08-1.57.16-.4.4-.6.64-.65.22-.05.53.01.86.3.33.3.63.77.77 1.37Zm19.2-4.7c.14.6.09 1.16-.08 1.57-.16.4-.4.6-.64.65-.22.05-.53-.01-.86-.3a2.64 2.64 0 0 1-.77-1.37c-.14-.6-.08-1.17.08-1.57.17-.4.41-.6.64-.66.23-.05.54.01.86.3.33.3.63.77.77 1.38Z"
            fill="#061D53"
            stroke="#061D53"
            strokeWidth="1.5"
        />
        <path
            d="M99.24 68.28c.17.6.13 1.16-.02 1.57-.15.41-.39.61-.61.68-.23.06-.54 0-.88-.27a2.64 2.64 0 0 1-.82-1.34c-.16-.6-.13-1.16.02-1.57.15-.41.39-.62.61-.68.23-.06.54-.01.88.27.34.27.65.74.82 1.34Zm19.01-5.46c.17.6.13 1.16-.02 1.57-.15.41-.38.62-.61.68-.23.06-.53.01-.88-.27a2.64 2.64 0 0 1-.81-1.34c-.17-.6-.14-1.16.01-1.57.15-.41.4-.62.62-.68.22-.06.53 0 .87.27.34.28.66.74.82 1.34Z"
            stroke="#061D53"
            strokeWidth="1.5"
        />
        <path
            d="M109.64 71.61s8.07 1.2 3.62 7.35m5.22 4.85s-7.37 4.77-16.54-.26m-13.5.4c-1.47-1.79-4.12-2.83-7.05-2.53-4.2.42-7.33 3.47-7 6.8.33 3.34 4.01 5.7 8.21 5.28a9.05 9.05 0 0 0 4.23-1.52l8.5 24.83"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M85.42 87.9s-3.03-2.41-4.75-2.7m2.31 1.47-1.91 1.66M58.94 51.89c-2.6 2.53-5.06 6.3-4.4 11.26 1.33 9.82 18.7 19.85 19.82 24.01m14.08-3.22c1.6 1.41 3.1 2.92 4.66 2.09 2.27-1.2 2.13-4.82-1.9-14.75-4.04-9.92-8.47-12.83.79-17.86 9.26-5.04 23.1-6.06 26.55-3.4 2.6 2 7 12.4 7 12.4s13.25.15 12.1-13.81c0 0 13.34-5.05 11.01-18.3-2.32-13.24-12.36-14.9-12.36-14.9.79.12 1.83-12.8-9.26-13.48-10.73-.68-22.8 6.76-28.06 16.5-7.05 13.1-12.15 12.54-14.57 12.69-2.43.14-16.88-1.51-20 15.59"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M64.44 46.74c-2.96-1.81-10.3-5.01-14.17-3.23-3.62 1.66 4.62 4.95 10.2 6.3m0 0c-1.62-.1-3.23-.16-4.85-.1a9.81 9.81 0 0 0-2.93.38 2.59 2.59 0 0 0-1.12.6c-.3.47.18.9.54 1.1 1.03.6 2.22.43 3.37.4 1.02-.03 2.44-.18 3.46-.3m36.42 64.92 16.28 9.77 11.75-11.18-2.12-10.91m-27.4 8.65c-45.47 11.23-77.16 40.81-73.02 70.52 1.83 13.13 10.37 24.32 23.38 32.59l27.3-40.34-8.92-6.48m112.28-12.55-9.63 7.6 26.47 39.92c17.34-13.58 26.96-30.74 24.56-47.95-4.27-30.76-44.92-50.86-93.93-47.63M68 181.36s11.5 5.84 9.66 16.05c-1.85 10.22-13.63 29.83-20.6 28.13-6.97-1.7-8.1-6.68-5.4-8.93m-4.83-4 6.94 5.6"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M168.21 169.57s-11.5 5.82-9.66 16.05c1.85 10.22 13.64 29.83 20.6 28.13 6.96-1.7 8.1-6.68 5.4-8.93m4.83-4-6.62 5.2"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M164.6 200.96s-40.06 13.64-87.91.85"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m111.33 224.77 45.74 105.02h50.5l-49.03-126.98m.45 127.3v24.3h9.9V346l7.52 8.46h64.18v-6.52s-26.06 1.72-35.73-17.66m-84.12-83.66-36.21 83.18h-50.5l48.86-125.83m-.28 126.16v24.3h-9.9v-8.43l-7.53 8.46H1v-6.52s26.05 1.72 35.73-17.66"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
