import { Box, BoxProps } from '@facile-it/full-metal-ui/components'
import * as React from 'react'

export interface HeroCaptionProps extends BoxProps {}

export const HeroCaption: React.FC<HeroCaptionProps> = ({ children, ...boxprops }) => (
    <Box
        component="p"
        display="block"
        fontSize={{ mobile: 1, desktop: 1.375 }}
        marginBottom={{ mobile: 'm', tablet: 'l' }}
        paddingBottom={{ mobile: '3xs', tablet: 'none' }}
        {...boxprops}
    >
        {children}
    </Box>
)
