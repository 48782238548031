import { Box, FlexView, Hidden, Image, TextPreset } from '@facile-it/full-metal-ui/components'
import * as React from 'react'
import contactsImg from '../components/branding/images/Contacts.svg'
import * as styles from '../styles/Pages.css'
import { Panel } from './datavisualization/Panel'

export const Faq: React.FC = () => (
    <FlexView.Container flexDirection={{ mobile: 'column', desktop: 'row' }}>
        <FlexView.Item width={{ mobile: 'full', desktop: '2/5' }}>
            <FlexView.Container alignItems="flexEnd">
                <Box
                    component="span"
                    className={styles.otherBlue}
                    paddingBottom={{ mobile: 'none', desktop: 'xxs' }}
                    fontSize={{ mobile: 1.375, desktop: 2.25 }}
                    fontWeight="bold"
                    marginBottom={{ mobile: 'none', desktop: 's' }}
                    display="block"
                    width="full"
                >
                    Domande frequenti
                </Box>
                <Hidden above="tablet">
                    <Image src={contactsImg.src} alt="Faq" className={styles.faqImage} loading="lazy" />
                </Hidden>
            </FlexView.Container>
            <TextPreset
                preset="paragraph-xl"
                fontWeight="medium"
                className={styles.specialLineHeight}
                paddingTop={{ mobile: 'm', desktop: 'none' }}
                paddingBottom={{ mobile: 'm', desktop: 'l' }}
                paddingRight="3xl"
            >
                Hai dubbi sull&apos;assicurazione auto o non sai cosa fare in caso di incidente? Scopri le risposte alle
                domande più richieste dai clienti.
            </TextPreset>
            <Hidden below="desktop">
                <Image src={contactsImg.src} alt="Faq" className={styles.faqImage} loading="lazy" />
            </Hidden>
        </FlexView.Item>
        <FlexView.Item width={{ mobile: 'full', desktop: '3/5' }}>
            <FlexView.Container flexDirection="column" space="s" paddingLeft={{ mobile: 'none', desktop: 'm' }}>
                <FlexView.Item>
                    <Panel title="Quali documenti servono per richiedere un'assicurazione online?">
                        Per calcolare il costo dell&apos;assicurazione auto è consigliabile tenere a portata di mano: la
                        carta d&apos;identità o la patente, il libretto di circolazione e l&apos;attestato di rischio.
                    </Panel>
                </FlexView.Item>
                <FlexView.Item>
                    <Panel title="Cosa fare nel caso in cui si verifichi un incidente?">
                        Se la tua polizza auto è stata acquistata online puoi contattare direttamente il servizio
                        clienti della tua assicurazione: ti verranno date le informazioni e il supporto necessari.
                    </Panel>
                </FlexView.Item>
                <FlexView.Item>
                    <Panel title="Assicurazione auto scaduta: posso essere multato?">
                        No. Con la circolare n. 300/A/1319/13/101/20/21/7 del 14 febbraio 2013, il Ministero
                        dell&apos;Interno ha chiarito che durante i 15 giorni di tolleranza si è ancora in regola.
                    </Panel>
                </FlexView.Item>
                <FlexView.Item>
                    <Panel title="Dopo il pagamento della polizza l'assicurazione è attiva da subito?">
                        I tempi variano a seconda della compagnia. Il regolamento ISVAP n° 13 del 6 febbraio 2008
                        prevede l&apos;invio dei documenti entro 5 giorni dall&apos;accertamento del pagamento.
                    </Panel>
                </FlexView.Item>
            </FlexView.Container>
        </FlexView.Item>
    </FlexView.Container>
)
