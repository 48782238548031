import { IconographyProps } from '@facile-it/full-metal-ui/components'
import { useIconSvgClassName } from '@facile-it/full-metal-ui/hooks'
export const ArrowLeftIcon: React.FC<IconographyProps> = p => {
    const cl = useIconSvgClassName(p)

    return (
        <svg className={cl} width="21" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m13.792.5-1.41 1.41 4.58 4.59H.792v2h16.17l-4.59 4.59 1.42 1.41 7-7-7-7Z" fill="currentColor" />
        </svg>
    )
}
