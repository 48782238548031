import { IconographyProps } from '@facile-it/full-metal-ui/components'
import { useIconSvgClassName } from '@facile-it/full-metal-ui/hooks'
export const MenuCloseIcon: React.FC<IconographyProps> = p => {
    const cl = useIconSvgClassName(p)

    return (
        <svg className={cl} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
                d="M20 2.01 17.99 0 10 7.99 2.01 0 0 2.01 7.99 10 0 17.99 2.01 20 10 12.01 17.99 20 20 17.99 12.01 10 20 2.01Z"
                fill="#061D53"
            />
        </svg>
    )
}
