import * as React from 'react'

export const ManOnTheCar: React.FC<{ className?: string }> = ({ className }) => (
    <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 544 355"
        width="100%"
        height="100%"
        role="img"
        aria-labelledby="assicurazioni page"
    >
        <title id="assicurazioni">Assicurazioni</title>
        <desc id="page">Assicurazioni</desc>
        <path
            d="M47.863 45.157a25.405 25.405 0 0 0-11.781-5.342C22.187 37.435 9.058 49.977 6.687 63.872l141.018-.052a21.355 21.355 0 0 0-8.693-21.01 21.35 21.35 0 0 0-22.703-1.26"
            fill="#fff"
        />
        <path
            d="M47.84 45.2a35.058 35.058 0 0 1 68.241-3.813m321.287 46.606a19.027 19.027 0 0 0-8.822-3.998c-10.405-1.778-20.236 7.61-22.018 18.014l105.6-.06a15.994 15.994 0 0 0-6.513-15.725 15.983 15.983 0 0 0-16.993-.941"
            fill="#fff"
        />
        <path
            d="M437.35 88.021a26.252 26.252 0 0 1 41.065-16.978 26.253 26.253 0 0 1 10.034 14.128M145.238 307.406l-1.934 9.499-4.22 9.672-5.98 9.143-6.331 5.981-9.672 5.634-9.148 3.691-13.012 2.463-16.007-.178-9.672-2.635-9.144-4.397-6.858-5.803-5.63-7.387-3.887-7.905-1.934-6.158-.888-9.85.35-1.579H1.202V205.366h147.205l-3.51-15.253-1.233-13.19 2.289-21.633 3.518-11.956 7.21-14.601 8.088-9.844 10.204-9.317 10.372-5.453 12.489-3.167h1.775l10.377-20.05-5.275-2.461-2.139-4.05.528-4.748 3.181-3.012 5.439-1.385 6.154.701 4.747 4.224 2.312 1.33 2.968-1.525 1.228-2.99.888-6.505-.173-7.914-.178-5.28 1.935-5.097 2.462-1.234 7.737-.528 10.905.888 7.387 1.774 5.626 3.341 1.415 2.25 1.047 5.665-.861 9.02-1.424 7.688-1.229 7.21-2.817 8.429-3.341 7.036-6.868 8.097-6.33 2.99-7.565.887-6.859-1.406-1.774-.528-2.813 5.63 12.311 13.54 8.088 13.017 2.462 7.209 1.234 7.915s26.029 5.098 28.664 7.21H378.6l20.226 46.428h6.859l92.36 39.21s11.784-8.429 29.898-7.737l11.783 42.209.173 28.669-159.511.528s-1.056 8.966-2.112 10.55c-1.056 1.584-6.508 13.367-6.508 13.367l-7.542 8.43-12.143 8.278-10.377 3.341-13.718 2.112-10.022-.355-8.43-1.051-12.839-4.574-9.179-7.015-7.564-10.727-3.164-7.037-1.584-10.204.351-4.569-130.319-.737Z"
            fill="#fff"
        />
        <path
            d="M221.367 69.204c.736.922 1.619 2.462 3.549 2.315 2.813-.217 4.987-1.694 5.284-13.465.297-11.77-2.888-16.659 8.678-17.657 11.566-.998 26.388 3.86 28.229 8.279 1.997 4.787.187 14.685.187 14.685s14.676 6.858 19.663-7.702c0 0 15.661.798 19.152-13.567 3.492-14.366-5.887-20.444-5.887-20.444.741.444 7.471-12.08-3.39-17.657-10.51-5.39-25.927-3.217-35.492 4.29-12.866 10.089-17.746 7.28-20.248 6.384-2.503-.896-16.34-8.944-26.988 6.89-2.183-3.128-8.159-9.574-12.839-9.485-4.379.08 2.471 7.01 7.484 10.825-1.575-.808-3.176-1.575-4.827-2.218a10.732 10.732 0 0 0-3.105-.91c-.351-.026-1.074-.089-1.385.107-.505.32-.217.989.054 1.353.78 1.047 2.045 1.402 3.221 1.876 1.047.444 2.537.888 3.62 1.225-3.74 1.402-7.879 4.122-9.388 9.396-2.99 10.475 10.075 28.226 9.379 32.915 2.4-1.193 5.226-2.218 7.897-1.992a10.203 10.203 0 0 1 5.998 2.582c.546.533.652 1.345 1.154 1.975Z"
            fill="#FFD05A"
        />
        <path
            d="m448.159 307.313-13.381.031a47.73 47.73 0 0 0-.079 2.764c0 23.824 18.536 43.141 41.406 43.141 22.87 0 41.406-19.317 41.406-43.141 0-.994-.035-1.979-.097-2.955l-12.112.035v6.713h-56.855s-.669-6.588-.288-6.588Zm-278.499 0-13.376.031a40.84 40.84 0 0 0-.085 2.764c0 23.824 18.536 43.141 41.407 43.141 22.87 0 41.406-19.317 41.406-43.141 0-.994-.033-1.979-.098-2.955l-12.111.035-57.143.125Z"
            fill="#FFF6DB"
        />
        <path
            d="M529.207 286.186c7.617 0 13.793-11.032 13.793-24.64 0-13.609-6.176-24.641-13.793-24.641-7.618 0-13.794 11.032-13.794 24.641 0 13.608 6.176 24.64 13.794 24.64Zm-119.459 0c7.618 0 13.793-11.032 13.793-24.64 0-13.609-6.175-24.641-13.793-24.641-7.618 0-13.793 11.032-13.793 24.641 0 13.608 6.175 24.64 13.793 24.64Z"
            fill="#FFD05A"
        />
        <path
            d="m126.456 204.381-5.275-20.794a10.039 10.039 0 0 1 7.245-12.165 10.034 10.034 0 0 1 12.165 7.24l6.589 25.963"
            fill="#F5F2D8"
        />
        <path
            d="m147.18 204.625-6.589-25.963a10.034 10.034 0 0 0-12.165-7.24 10.039 10.039 0 0 0-7.245 12.165l5.275 20.794"
            fill="#FFF6DB"
        />
        <path
            d="m47.983 204.381-5.28-20.794a10.04 10.04 0 0 1 7.25-12.165 10.036 10.036 0 0 1 12.165 7.24l6.588 25.963"
            fill="#F5F2D8"
        />
        <path
            d="m47.983 204.381-5.28-20.794a10.04 10.04 0 0 1 7.25-12.165 10.036 10.036 0 0 1 12.165 7.24l6.588 25.963"
            fill="#FFF6DB"
        />
        <path d="M1.036 239.757h14.756a9.725 9.725 0 0 0 9.693-9.693v-24.699H1.005l.03 34.392Z" fill="#FFD05A" />
        <path
            d="M241.904 61.867c.43-1.367-.009-2.723-.982-3.03-.972-.305-2.109.555-2.539 1.923-.43 1.367.009 2.724.982 3.03.972.305 2.109-.555 2.539-1.923Zm17.862 5.416c.43-1.367-.009-2.724-.982-3.03-.972-.306-2.109.555-2.539 1.922-.43 1.368.01 2.724.982 3.03.972.306 2.109-.555 2.539-1.922Z"
            fill="#061D53"
        />
        <path
            d="m126.456 204.381-5.275-20.794a10.039 10.039 0 0 1 7.245-12.165v0a10.034 10.034 0 0 1 12.165 7.24l6.589 25.963m142.378-23.142c3.46-8.939 4.241-15.971 1.495-17.497-3.842-2.117-13.128 7.351-20.741 21.153-4.019 7.28-6.766 14.312-7.906 19.641"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M316.621 204.856c-3.833-13.106-31.899-25.839-31.899-25.839s-10.204 11.628-13.429 26.025h8.949v-.035l36.379-.151ZM267.308 63.361c-2.32 15.084-8.908 43.789-28.713 42.169-23.514-1.921-22.045-19.45-22.045-19.45m12.169 17.653-6.797 13.168m-3.638-37.533a11.728 11.728 0 0 1-5.675 1.415c-5.479 0-9.924-3.549-9.924-7.883 0-4.335 4.436-7.88 9.924-7.88 3.794 0 7.099 1.69 8.758 4.17"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M216.948 73.91s-3.589-3.49-5.768-4.085m2.779 2.191-2.693 1.894m36.113-2.79s5.768 5.085-.887 7.68m1.579 6.682s-8.279 0-12.968-8.678m-28.815-52.486c-3.74 1.402-7.88 4.122-9.388 9.397-2.99 10.474 10.075 28.225 9.379 32.914m15.087 2.575c.737.922 1.62 2.462 3.549 2.315 2.813-.217 4.987-1.694 5.284-13.465.298-11.77-2.888-16.659 8.678-17.657 11.566-.998 26.389 3.86 28.23 8.279 1.996 4.787.186 14.685.186 14.685s14.676 6.858 19.663-7.702c0 0 15.661.798 19.153-13.567 3.491-14.366-5.888-20.444-5.888-20.444.741.444 7.472-12.08-3.389-17.657-10.51-5.39-25.927-3.217-35.492 4.29-12.866 10.089-17.747 7.28-20.249 6.384-2.502-.896-16.34-8.944-26.987 6.89"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M214.105 21.555c-2.183-3.128-8.159-9.574-12.84-9.486-4.378.08 2.472 7.01 7.485 10.825m-.019.001c-1.575-.808-3.176-1.575-4.827-2.219a10.729 10.729 0 0 0-3.105-.91c-.351-.026-1.074-.088-1.384.107-.506.32-.218.99.053 1.353.781 1.047 2.045 1.402 3.221 1.877 1.047.444 2.537.887 3.62 1.225m4.087 56.309-12.125 23.203m0-.001 29.148 16.446-3.345-7.085m-75.866 91.349c-1.637-5.323-2.755-8.992-3.514-14.8-5.767-44.325 17.897-83.953 52.879-88.513a55.119 55.119 0 0 1 2.032-.227"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M148.048 204.416c-1.632-5.323-2.595-8.873-3.354-14.667-5.767-44.325 17.897-83.953 52.879-88.513a55.119 55.119 0 0 1 2.032-.227m91.448 62.977s-31.025-18.727-75.59-11.979"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M190.236 204.651c-15.635-9.347-27.848-19.143-33.39-48.615-4.516-24.002 16.944-44.219 37.844-44.219 19.188 0 35.049 16.681 37.516 38.305m84.414 54.734c-3.833-13.106-31.898-25.839-31.898-25.839m16.743 20.488a39.014 39.014 0 0 1 5.155 5.324m-12.866-2.636a52.003 52.003 0 0 1 3.26 2.898m-70.699-95.036s23.189 17.928 23.722 40.932"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M314.287 199.398c10.612-17.635 26.398-27.95 36.65-22.795 7.587 3.811 10.324 14.858 8.075 28.066"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M308.719 193.058c12.737-19.902 31.673-30.789 44.578-24.707 10.648 5.018 13.128 19.863 10.75 35.723m141.508 103.265h34.441v-29.884m-173.461 29.884h81.548m-38.335-21.153c7.618 0 13.793-11.032 13.793-24.64 0-13.609-6.175-24.641-13.793-24.641-7.618 0-13.793 11.032-13.793 24.641 0 13.608 6.175 24.64 13.793 24.64Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M408.319 286.186c-9.241 0-16.73-11.029-16.73-24.64 0-13.612 7.489-24.645 16.73-24.645m120.888 49.285c7.617 0 13.793-11.032 13.793-24.64 0-13.609-6.176-24.641-13.793-24.641-7.618 0-13.794 11.032-13.794 24.641 0 13.608 6.176 24.64 13.794 24.64Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M527.768 286.186c-9.241 0-16.734-11.029-16.734-24.64 0-13.612 7.493-24.645 16.734-24.645"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M498.208 243.684a45.344 45.344 0 0 1 15.794-6.251 42.658 42.658 0 0 1 15.2-.51m-123.695-32.232 98.296 41.424m1.509 47.409h-56.837v20.545h56.837v-20.545ZM25.503 205.365v24.694a9.727 9.727 0 0 1-9.712 9.698H1.018m130.444 67.582H275.37"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1 205.41v101.93h39.822m196.881-101.925 133.301 43.673c4.605-5.324 12.688-9.836 22.404-11.814a46.58 46.58 0 0 1 17.347-.386m-241.663-1.727h-22.241v5.444h22.241v-5.444Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M196.155 307.331h-26.176a33.138 33.138 0 0 1-33.07-33.044v-68.881m286.632 56.139h87.501m-105.536-56.854-170.368.573m95.608-.382c3.678-6.109 9.148-9.681 12.701-7.897 2.254 1.131 3.275 4.104 3.026 7.817m-220.017-.421-5.275-20.794a10.039 10.039 0 0 1 7.245-12.165v0a10.034 10.034 0 0 1 12.165 7.24l6.589 25.963m-99.197-.244-5.28-20.794a10.04 10.04 0 0 1 7.25-12.165v0a10.036 10.036 0 0 1 12.165 7.24l6.588 25.963"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m47.983 204.381-5.28-20.794a10.04 10.04 0 0 1 7.25-12.165v0a10.036 10.036 0 0 1 12.165 7.24l6.588 25.963m330.038.106-19.827-46.247H229.295l15.164 46.26m284.748 81.442c7.617 0 13.793-11.032 13.793-24.64 0-13.609-6.176-24.641-13.793-24.641-7.618 0-13.794 11.032-13.794 24.641 0 13.608 6.176 24.64 13.794 24.64Zm-119.459 0c7.618 0 13.793-11.032 13.793-24.64 0-13.609-6.175-24.641-13.793-24.641-7.618 0-13.793 11.032-13.793 24.641 0 13.608 6.175 24.64 13.793 24.64Zm-217.79-81.495L1 205.41m81.68 147.838c59.836 2.245 62.414-45.62 62.414-45.62"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M82.68 353.249c22.868 0 41.406-19.315 41.406-43.141 0-23.826-18.538-43.141-41.406-43.141s-41.406 19.315-41.406 43.141c0 23.826 18.538 43.141 41.406 43.141Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M131.461 307.002s-4.547-54.756-52.28-53.278c-44.809 1.384-51.087 44.809-49.36 53.606h11.006"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M317.383 324.061c7.763 0 14.055-7.24 14.055-16.171s-6.292-16.171-14.055-16.171c-7.762 0-14.055 7.24-14.055 16.171s6.293 16.171 14.055 16.171Z"
            fill="#FFF6DB"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M315.724 353.373c60.989 4.379 64.441-46.064 64.441-46.064"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M317.224 353.249c22.868 0 41.406-19.315 41.406-43.141 0-23.826-18.538-43.141-41.406-43.141s-41.406 19.315-41.406 43.141c0 23.826 18.538 43.141 41.406 43.141Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M366.229 307.406s-4.774-55.16-52.502-53.682c-44.809 1.384-51.091 44.809-49.361 53.606h11.003m159.409.014a47.73 47.73 0 0 0-.079 2.764c0 23.824 18.536 43.141 41.406 43.141 22.87 0 41.406-19.317 41.406-43.141 0-.994-.035-1.535-.097-2.511m-347.754-.284-13.376.031a40.84 40.84 0 0 0-.085 2.764c0 23.824 18.536 43.141 41.407 43.141 22.87 0 41.406-19.317 41.406-43.141 0-.994-.033-1.979-.098-2.955l-12.111.035-57.143.125Zm63.407-90.648h-37.662a3.106 3.106 0 0 1-3.106-3.106v-21.65a3.106 3.106 0 0 1 3.106-3.106h36.415c.824 0 1.614.327 2.196.91.583.582.91 1.372.91 2.196v17.533"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M244.423 215.18c-.386-.098-6.268-3.661-9.671-5.728l-2.383-1.451m-3.623 6.837c.444.346 13.048 5.15 13.048 5.15"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M82.84 324.061c7.762 0 14.055-7.24 14.055-16.171s-6.293-16.171-14.055-16.171c-7.763 0-14.055 7.24-14.055 16.171s6.293 16.171 14.055 16.171Z"
            fill="#FFF6DB"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
