import { Box, TextPreset } from '@facile-it/full-metal-ui/components'
import { useBrowserContextRunDispatch } from '@facile-it/full-metal-ui/providers/Root'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import * as React from 'react'
import { Icon, IconSVG } from '../../../components'
import { routes } from '../../../routes'
import { HeaderLogo } from '../../branding'
import { AppLink } from '../../routing/AppLink'
import { Links } from './Links'
import * as styles from './Mobile.css'

export interface MobileProps {}

export const Mobile: React.FC<MobileProps> = () => {
    const [open, setOpen] = React.useState(false)
    const rootRunDispatch = useBrowserContextRunDispatch()
    const { events } = useRouter()

    React.useEffect(() => {
        if (open === false) {
            return
        }

        rootRunDispatch({ type: 'bodyScroll', payload: 'blocked' })

        return () => {
            rootRunDispatch({ type: 'bodyScroll', payload: 'auto' })
        }
    }, [rootRunDispatch, open])

    React.useEffect(() => {
        const handleRouteChange = () => setOpen(false)

        events.on('routeChangeComplete', handleRouteChange)

        return () => {
            events.off('routeChangeComplete', handleRouteChange)
        }
    }, [events])

    return (
        <Box
            alignItems="center"
            className={styles.container}
            display="flex"
            flexDirection="row"
            justifyContent="spaceBetween"
            paddingX="m"
        >
            <div className={styles.button} onClick={() => setOpen(!open)}>
                <IconSVG variant={open ? 'menuclose' : 'menu'} alt="menu" />
            </div>
            <Box display="flex" flex={1} alignItems="center" flexDirection="column">
                <AppLink href={routes.root}>
                    <HeaderLogo />
                </AppLink>
                <TextPreset preset="paragraph-s">Prodotto di Wakam S.A., creato per Brokerhero</TextPreset>
            </Box>
            <a className={clsx(styles.button, styles.buttonfilled)} href={routes.personalarea}>
                <IconSVG variant="userMobile" />
            </a>
            <nav className={clsx(styles.nav, open && styles.isopen)}>
                <Links className={styles.link} />
                <hr className={styles.hr} />
                <AppLink className={styles.link} href={routes.personalarea}>
                    Area riservata
                </AppLink>
            </nav>
        </Box>
    )
}
