import { IconographyProps } from '@facile-it/full-metal-ui/components'
import { useIconSvgClassName } from '@facile-it/full-metal-ui/hooks'
export const MenuIcon: React.FC<IconographyProps> = p => {
    const cl = useIconSvgClassName(p)

    return (
        <svg className={cl} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 20">
            <path d="M0 20h30v-3.33H0V20Zm0-8.33h30V8.33H0v3.34ZM0 0v3.33h30V0H0Z" fill="#061D53" />
        </svg>
    )
}
