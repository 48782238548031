import { Box, BoxProps } from '@facile-it/full-metal-ui/components'
import * as React from 'react'
import * as styles from './Container.css'

export interface ContainerProps extends BoxProps {}

export const Container: React.FC<ContainerProps> = ({ className, children, ...props }) => (
    <Box className={[styles.container, className]} {...props}>
        {children}
    </Box>
)
