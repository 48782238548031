import { Box, BoxProps } from '@facile-it/full-metal-ui/components'
import Link from 'next/link'
import * as React from 'react'
import * as styles from '../layout/header/Link.css'

export interface AppLinkProps extends BoxProps {
    current?: boolean
    href: string
    footer?: boolean
}

export const AppLink: React.FC<AppLinkProps> = ({ className, footer, href, current, children, ...props }) => (
    <Link href={href} passHref>
        <Box
            component="a"
            cursor="pointer"
            className={[{ [styles.linkCurrent]: current }, { [styles.linkCurrentFooter]: footer }, className]}
            {...props}
        >
            {children}
        </Box>
    </Link>
)
