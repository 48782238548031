import { FlexView } from '@facile-it/full-metal-ui/components'
import { ContainerProps } from '@facile-it/full-metal-ui/components/structural/FlexView'
import * as React from 'react'
export interface SpecContainerProps extends ContainerProps {
    twolines?: boolean
}

export const SpecContainer: React.FC<SpecContainerProps> = ({ twolines, ...props }) => (
    <FlexView.Container
        alignItems={{
            mobile: 'flexStart',
            desktop: 'center',
        }}
        flexDirection={{
            mobile: 'column',
            desktop: 'row',
        }}
        justifyContent={twolines ? 'spaceEvenly' : 'spaceBetween'}
        {...props}
    />
)
