import { Box, FlexView, Image, TextPreset } from '@facile-it/full-metal-ui/components'
import * as React from 'react'
import { getIconImgSrc, IconVariant } from '../branding/icon/get-icon-src'
import * as styles from './Spec.css'
import { SpecWrapper } from './SpecWrapper'

export interface SpecProps {
    title?: React.ReactNode
    type?: IconVariant
    children: React.ReactNode
}

export const Spec: React.FC<SpecProps> = ({ children, title, type }) => {
    const { src } = getIconImgSrc(type)

    return (
        <FlexView.Item
            className={styles.specsWrapper}
            marginBottom={{
                mobile: 'l',
                desktop: 'none',
            }}
            width={{
                mobile: 'full',
                desktop: '1/5',
            }}
            paddingBottom={{
                mobile: 'xs',
                desktop: 'none',
            }}
        >
            <Box
                display="flex"
                alignItems={{
                    mobile: 'flexStart',
                    desktop: 'center',
                }}
                flexDirection={{
                    mobile: 'row',
                    desktop: 'column',
                }}
                justifyContent={{
                    mobile: 'flexStart',
                    desktop: 'center',
                }}
                textAlign={{
                    mobile: 'left',
                    desktop: 'center',
                }}
            >
                {typeof src === 'string' && (
                    <SpecWrapper className={styles.imgWrapper}>
                        <Image
                            className={[{ [styles.imgSpecial]: title === 'Sicura' }, styles.img]}
                            height="full"
                            src={src}
                            alt={`${title}`}
                        />
                    </SpecWrapper>
                )}
                <Box
                    marginTop={{ mobile: 'xxs', desktop: 'l' }}
                    marginLeft={{ mobile: 'm', desktop: 'none' }}
                    width={{ mobile: '2/3', desktop: 'full' }}
                >
                    {Boolean(title) && (
                        <TextPreset
                            component="p"
                            fontWeight="bold"
                            preset="title-s"
                            marginBottom={{ mobile: 'xs', desktop: 's' }}
                            lineHeight="xs"
                        >
                            {title}
                        </TextPreset>
                    )}
                    <TextPreset preset="paragraph-xl">{children}</TextPreset>
                </Box>
            </Box>
        </FlexView.Item>
    )
}
