import { ColorsThemedToken } from '@facile-it/full-metal-ui/themes'
import { colorsTokensCommon } from '@facile-it/full-metal-ui/themes/_commons/colorsCommon'
import { contentWidthTokensCommon } from '@facile-it/full-metal-ui/themes/_commons/contentWidthCommon'
import { miscTokensCommon } from '@facile-it/full-metal-ui/themes/_commons/miscCommon'
import { spaceTokensCommon } from '@facile-it/full-metal-ui/themes/_commons/spaceCommon'
import { FmuTokensRaw } from '@facile-it/full-metal-ui/themes/makeFullMetalUiTheme'

const grey = {
    0: '#fdfdfd',
    100: '#f2f2f2',
    150: '#f9f9f9',
    200: '#e5e5e5',
    250: '#dddddd',
    300: '#d7d7d7',
    400: '#373737',
    500: '#cccccc',
    600: '#979797',
    700: '#505050',
}
const primary = '#061D53'
const secondary = '#FED05D'
const third = '#0A0340'
const fourth = '#E6ECF2'
const veryPaleOrange = '#ffeee0'
const white = '#FFFFFF'

export const colorsSaturno: ColorsThemedToken = {
    primary500: fourth,
    primary400: third,
    primary300: secondary,
    primary200: primary,
    primary100: '#FFEEE0',
}

export const tokens: FmuTokensRaw = {
    name: 'Saturno',
    displayName: 'Saturno',
    typography: {
        fontFamily: {
            std: "'Montserrat', sans-serif",
            alt: 'Arial, Helvetica, sans-serif',
        },
        fontSizeRoot: {
            value: 16,
            unit: 'px',
        },
        color: {
            std: third,
            selection: veryPaleOrange,
        },
        fontWeight: {
            normal: '400',
            medium: '500',
            bold: '700',
        },
        letterSpacing: {
            default: { value: 0, unit: 'em' },
            s: { value: 0.005, unit: 'em' },
            m: { value: 0.01, unit: 'em' },
            l: { value: 0.04, unit: 'em' },
            xl: { value: 1, unit: 'em' },
            xxl: { value: 4.99, unit: 'em' },
        },
        lineHeight: {
            xs: { value: 1 },
            s: { value: 1.15 },
            m: { value: 1.2 },
            l: { value: 1.3 },
            xl: { value: 1.4 },
            xxl: { value: 2 },
        },
    },
    ...spaceTokensCommon,
    ...contentWidthTokensCommon,
    ...miscTokensCommon,
    body: {
        background: {
            mobile: grey['100'],
            mobileXl: grey['100'],
            tablet: white,
            desktop: white,
        },
    },
    colors: {
        ...colorsTokensCommon(colorsSaturno).colors,
        success400: primary,
        help300: secondary,
        help400: third,
        success300: fourth,
    },
}
