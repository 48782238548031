import '@facile-it/full-metal-ui/css/common'
import { FullMetalUiProvider } from '@facile-it/full-metal-ui/providers'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import * as React from 'react'
import { Layout } from '../components/layout/Layout'
import * as ga from '../lib/ga'
import { saturnoTheme } from '../theme/index'

function MyApp({ Component, pageProps }: AppProps) {
    const whichEnv = process.env.NODE_ENV === 'development' ? 'dev' : 'prod'
    const router = useRouter()

    const [oneTrustScript, setOneTrustScript] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        const handleRouteChange = (url: URL) => {
            ga.pageview(url)
        }

        //When the component is mounted, subscribe to router changes
        //and log those page views
        router.events.on('routeChangeComplete', handleRouteChange)

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])

    React.useEffect(() => {
        if (
            !/bot|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|yandex|Chrome-Lighthouse/i.test(
                window.navigator.userAgent
            )
        ) {
            setOneTrustScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js')
        }
    }, [])

    return (
        <FullMetalUiProvider headWrapper={Head} env={whichEnv} theme={saturnoTheme}>
            <Layout>
                <Head>
                    <meta
                        name="description"
                        content="Saturno assicurazioni - L'Assicurazione che sa quello che vuoi."
                    />
                    <link rel="icon" type="image/svg+xml" href="./favicon.svg" />
                    <link rel="icon" type="image/png" href="./favicon.png" />
                    <title>Saturno assicurazioni</title>
                </Head>
                {oneTrustScript ? (
                    <>
                        <Script
                            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                            data-language="it"
                            charSet="UTF-8"
                            data-domain-script={process.env.NEXT_PUBLIC_ONE_TRUST}
                        />
                        <Script
                            id="one-trust"
                            dangerouslySetInnerHTML={{
                                __html: `
                                function OptanonWrapper() { }
                            `,
                            }}
                        />
                    </>
                ) : null}
                <Script
                    src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
                    strategy="lazyOnload"
                />
                <Script
                    id="google-analytics"
                    strategy="lazyOnload"
                    dangerouslySetInnerHTML={{
                        __html: `
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                                    page_path: window.location.pathname,
                                });
                            `,
                    }}
                />
                <Component {...pageProps} />
            </Layout>
        </FullMetalUiProvider>
    )
}
export default MyApp
