import * as React from 'react'

export const Chilling: React.FC<{ className?: string }> = ({ className }) => (
    <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 515 282"
        width="100%"
        height="100%"
        role="img"
    >
        <title id="chi">Chi Siamo</title>
        <path
            d="M71.88 12.6s-7.54-5.7-15.09-4.7c-7.54 1.02-13.24 9.17-14.06 22.81-.82 13.64 4.28 22.4-.61 30.54-4.9 8.15-22.62 14.66-30.78 23.62s-21.8 30.95 8.36 54.97c0 0 14.68 10.39 32.4-.2 0 0-19.78-40.72-10.39-52.94 9.4-12.22 21.21-14.86 21.21-14.86l6.52-29.93-4.07-1.63-1.03-4.07 3.67-2.85 4.5 3.26-.2 5.3 1.63-.21s4.69-5.5 4.28-13.03a30.1 30.1 0 0 0 18.14-2.44s4.69 7.94 3.87 16.29c0 0-5.7 26.67-22.42 20.56v11s25.89 6.73 34.45 21.17c0 0 8.35-11 .61-23.8 0 0 4.9-22.4-7.75-34.21 0 0 15.3-19.34-4.28-31.15-.02-.04-16.53-14.48-28.96 6.5Z"
            fill="#FFD05A"
        />
        <path
            d="M96.45 26.22s-6.41 5.42-17.6 1.8c0 0-2.08 14.37-5.75 14.13 0 0-1.48-1.15-.32-5.1 0 0-1.32-3.12-3.95-3.28-2.63-.16-4.75 2.38-4.11 4.44 0 0 .5 2.96 4.6 4.1l-5.59 28.91S49.1 76.64 45.15 82.56c-3.94 5.91-10.2 5.09-2.13 34.16l18.24 44.34c.21.5-.48 2.22-.57 2.74a75.51 75.51 0 0 0-1 7.24c-.6 6.88-.4 14.03 1.63 20.68A27.57 27.57 0 0 0 72.5 206.9c3.87 2.66 9.45 3.2 14.08 3.69 5.45.56 10.94.56 16.4.54 16.74-.04 33.5-.5 50.2.35 0 0-9.7 25.13 3.59 52.55a13.42 13.42 0 0 0 10.34-.82s12-28.9 13.65-44.5l24.7 22.33s7.07-.33 7.72-7.4c0 0-23.67-55.5-35.34-65.2 0 0-15.46-15.76-51.96-3.11l-15.46-5.91 1-7.72 11-4.27 32.56-21.19s4.6-5.42-1.15-10.5h-11.18s-21.54-9.52-29.6-19.88c0 0-13.97-16.75-35.34-21.85l-.33-11s10.68 4.27 18.41-7.39 3.78-27.26.66-29.4Z"
            fill="#fff"
        />
        <path
            d="m147.54 135.9 16.92-4.08 29.76-47.84 73.98-.2-29.15 52.11h-91.51Zm-8.16.4H513.4v8.76H126.14l13.25-8.76Zm-123.3 1.43s17.32 13.23 36.07 2.24l8.77 21.38S50.12 202.67 80.08 210c0 0 40.96 1.63 46.47 1.22 0 0 4.89 5.3-2.65 9.57 0 0-56.46 7.94-86.83-11.4 0 0-26.7-14.24-21-71.67Zm393.98-53.14-86.42-.4 20.79 52.11h86.21L410.06 84.6Z"
            fill="#fff"
        />
        <path
            d="M350.12 84s20.3-21.15 35.7-22.37c0 0 46.38-2.1 67.55 26.57 0 0 16.27 15.9 13.65 47.72h-36.23l-80.67-51.91Z"
            fill="#fff"
        />
        <path
            d="M389.32 33.14s-6.3 15.56-4.55 23.07c1.75 7.52 6.3 25.16 29.4 16.43 0 0 7.87-2.27 10.67-26.05 0 0 .7-6.29.53-7.51 0 0-12.77 1.92-17.15-15.03l-9.28 1.92s-.87 9.1-9.62 7.17Z"
            fill="#fff"
        />
        <path
            d="M386.7 38.43s-11.24-27.48 18.14-31.8c0 0 10.66-.12 17.33 4.44 0 0 20.13-3.63 26.22 6.43 0 0 5.4 18.95-9.71 20.7 0 0 0 6.2-3.15 7.48 0 0 .58 5.73-5.15 7.6 0 0-1.06-3.04-5.5-.23l.17-6.74-3.33-3.79-1.8-3.68-12.54-14.98-8.72 3.01-3.6 5.82-5.92.9-2.44 4.84Z"
            fill="#FFD05A"
        />
        <path
            d="M386.93 39.95s-2.1-3.5-3.15-3.5c-1.04 0-2.45.58-2.45 2.45s-.35 5.39 2.57 7.49l1.64.7 1.39-7.14Zm36.88 12.98s3.74-2.45 5.15-1.05c0 0 2.1 2.57.7 4.8 0 0-4.21 4.56-6.79 3.62l.94-7.36Zm41.18 92.79-98.88-.26-6.02 25.6s-28.51 3.66-33.23 16.46l-16.19 81.01h35.08l20.68-79.17-16.28 78.39 34.27.52 21.7-77.08s44.48-2.23 58.87-45.47Z"
            fill="#fff"
        />
        <path d="M381.11 268.32v11.78h-6.03V276l-4.6 4.13h-39.04v-3.16s15.85.83 21.75-8.57" fill="#FFF6DB" />
        <path d="M342.45 268.32v11.78h-6.03V276l-4.58 4.11h-39.07v-3.16s15.86.83 21.76-8.56" fill="#FFF6DB" />
        <path
            d="M442.66 77.05s30.52 21.07 24.36 58.87h18.6s-4.3-40.19-42.96-58.87ZM465 145.72s-8.22 39.2-58.86 45.47l-3.9 13.83s91.47 10.33 84.9-59.32l-22.14.02Z"
            fill="#fff"
        />
        <path
            d="M167.12 263.3s8.07 11.45 18.44 13.18c0 0 3.24.86 2.81 3.34h-27.13s-9.3-1.1-4.75-15.34m56.61-29.4s10.7 1.8 17.58-2.93c0 0 2.33-1.3 3.46.36l-15.5 15s-6.48 4.18-11.78-6.47"
            fill="#FFF6DB"
        />
        <path
            d="m153.33 115.69 8.47.45 7.82 2.6 1.96 1.56-7.12 11.53-6.56.78-2.8-1.5s-2.15-2.08-2.02-2.34l1.04-2.02 1.96-2.34-.26-3.84-.79-2.8-1.7-2.08Z"
            fill="#fff"
        />
        <path
            d="M84.33 39.46c.04.01.13.06.2.26.08.2.1.5.01.83a1.4 1.4 0 0 1-.43.72c-.16.13-.27.13-.3.12-.05-.01-.14-.06-.22-.27-.07-.2-.1-.5 0-.83.09-.33.26-.57.43-.7.16-.15.27-.14.3-.13Zm11.15 3.4c.04.01.14.06.21.26.08.2.1.5 0 .83-.08.34-.25.58-.42.72-.17.13-.28.13-.31.12-.04-.02-.14-.06-.21-.27-.08-.2-.1-.5 0-.83.08-.33.25-.57.42-.71.17-.14.28-.13.31-.12Z"
            fill="#061D53"
            stroke="#061D53"
            strokeWidth="1.5"
        />
        <path
            d="M89.4 45.83s4.05 3.28-.4 5.2m1.5 3.94s-5.54.7-9.44-4.7m189.4 65.41a18.38 18.38 0 0 0-.2 2.56 18.17 18.17 0 0 0 5.44 12.67 18.2 18.2 0 0 0 25.53 0 18.17 18.17 0 0 0 5.43-12.67 18.38 18.38 0 0 0-.19-2.56h-36.02Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M307.49 119.18a4.95 4.95 0 0 1 4.59 6.8 4.93 4.93 0 0 1-3.57 2.98 4.95 4.95 0 0 1-4.46-1.3m120.98-81.35s-1.4 34.05-24.73 28.27c-23.33-5.78-13.94-35.03-11.16-41.01"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M386.75 38.7s-5.17-16.02 1.07-23.55c8.06-9.72 21.44-11.4 35.14-4.25"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M418.24 13.63s5.78-5.47 21.13-2.58c16.64 3.14 10.34 27.65 0 27.35"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M437.86 36.27s2.74 5.62-2.44 9.43m-43.19-20.52s7 3.04 18.86-3.2m-2.66 1.64s2.89 18.54 18.25 14.9m-2.25 14.65s5.18-3.94 5.63.16c.46 4.1-3.2 7.29-7 6.83m-36.18-20.33s-3.14-5.7-5.21-2.12 0 7.96 3.65 9.07"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M430.06 53.33s6.43-.95 4.6-8.99m-3.59 92.07-20.8-52.35h-86.3m20.57 52.05L323.9 84.07m26.11-.23s17.54-18.86 35.78-22.31m34.25 46.77s14.54-1.4 27.8 5.03m-81.87 77.98-19.99 76.95H310.6l15.96-80.32s4.01-12.39 33.2-16.4l6.46-26.17M406.1 190.86l-21.06 77.3h-35.4l17.05-80.22m36.35 16.59s84.72 8.65 84.8-52.1m-.34-6.92c.19 2.24.3 4.54.34 6.9m-67.21-86s55.75 15.94 65.64 69.87"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M413.6 53.55c.12.04.26.17.33.48.07.3.05.71-.12 1.14-.18.43-.45.74-.71.9-.27.17-.46.17-.57.12-.12-.04-.26-.18-.33-.48s-.05-.71.12-1.14c.18-.43.45-.74.71-.9.27-.18.46-.17.58-.12Zm-18.26-6.5c.12.05.26.18.33.49.07.3.05.7-.13 1.14-.17.43-.44.74-.7.9-.27.17-.46.17-.58.12-.11-.05-.25-.18-.32-.48-.08-.3-.05-.72.12-1.15.18-.43.45-.73.71-.9.26-.17.46-.16.57-.12Z"
            fill="#061D53"
            stroke="#061D53"
            strokeWidth="1.5"
        />
        <path
            d="M394.15 62.65s4.17 5.57 12.36 4.36m-6.55-9s-1.84 3.07 2.46 3.48m62.75 83.8c-7.57 25.09-30.68 43.76-58.58 45.64m13.56-124.68c27.51 7.63 47.66 32.13 47.66 61.17a62.01 62.01 0 0 1-.64 8.92m-135.22 143.8h-39.06v-3.17s15.86.83 21.76-8.57m27.91-.08v7.2"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M381.11 268.32v11.78h-6.03V276l-4.6 4.13h-39.05v-3.16s15.86.83 21.76-8.57m45.58-242.16s-1.75 9.15-10.79 6.71M72.6 58.16s4.9 8.04 13.3 5.25c6.3-2.1 14.76-11.18 14.47-23.7a31.29 31.29 0 0 0-3.6-13.54"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M98.72 23.96s-7 8.24-20.26 3.86c0 0-1.26 13.39-5.01 14.29-2.61.63-.1-6.26-.1-6.26m-.6 1.48s-3-5.22-5.78-3.74c-2.8 1.49-4.05 7.41 2.83 8.36m.62-2.56-1.36-2.6m.31 1.66-1.67.21m2.12 3.29-6.3 29.26s1.57 5.31 14 4.49V62.97"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M63.53 71.19s-21.51 8.15-24 20.66"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m111.83 151.59-1.32 7.63c-26.62 8.88-49.39 1.69-49.39 1.69L49.52 132c-12.4-25.03-9.98-40.16-9.98-40.16m4.04-7.6s11.6-12.1 30.7 8.23c16.6 17.68 28.4 28 28.4 28"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M99.03 121.22s41.68-6.05 54.5-5.63c0 0 6.8 6.67-.52 12.1-7.3 5.42-28.94 18.25-28.94 18.25-15.87 9.28-24.64 7.18-36.23.31-10.6-6.3-46.95-39.13-46.95-39.13"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M77.73 73.97s19.34 4.17 33.31 19.71c13.08 14.54 27.56 20.97 32.78 22.22m9.72-.31s10.65-.45 17.59 4.44"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m145.81 136.32 18.69-4.88 30.17-47.77h73.72l-29.06 51.98M61.12 160.9s-13.31 52.25 32.64 50.27c38.67-1.66 46.01-13.82 73.52-18.48"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m181.3 218.51 24.16 22.32s6.66.16 7.83-7c0 0-20.17-49.8-35.17-65.13-22-22.45-85.03 9.16-85.03 9.16m5.01 33.16h55.18"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M167.3 192.7s23.66-.69 9.16 43.78l-9.34 26.8s-6.33 3.5-10.67.5c0 0-13.67-30.63-2.16-53.78"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M167.12 263.3s8.07 11.45 18.44 13.18c0 0 3.24.86 2.81 3.34h-27.13s-9.3-1.1-4.75-15.34m56.61-29.4s10.7 1.8 17.58-2.93c0 0 2.33-1.3 3.46.36l-15.49 15s-6.49 4.18-11.8-6.47M94.02 93.44a11.6 11.6 0 0 1 5.64 4.3 11.57 11.57 0 0 1 2.08 6.77 11.7 11.7 0 0 1-5.55 10.05m-43.94 25.3c-4.62 4.61-33.16 12.18-47.52-17.8-14.35-29.98 16.33-45.65 30.2-53.4 6.36-3.56 8.99-10.35 8.9-17.37-.03-3.14-.79-6.06-1.2-9.14-.68-5.21-.22-10.52.66-15.68.99-5.84 2.8-12.7 7.67-16.56 6.42-5.08 14.97-1.29 20.97 2.57 0 0 14.65-26.19 35.75.54 7.48 9.48-2.18 23.51-2.18 23.51"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.28 137.72s-7.77 62.79 29.37 76.3c37.13 13.52 78.53 6.26 78.53 6.26s6.27-1.32 2.97-8.73"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.11 161.28c2.48-5.93 7.57-9.03 12.77-8.9 9.34.2 17.71 9.77 20.42 18a93.96 93.96 0 0 0 2.28 6c5.06 12.52 15.14 32.77 30.84 33.98m-7.39 10.51v49.24m0 .6s-12.56 10.32-19.95 9.79H81.4"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M75.03 270.72s12.56 10.3 19.95 9.79H68.67m350.34-75.37v64.97m0 .6s-12.56 10.32-19.95 9.79h26.31"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M419 270.72s12.56 10.3 19.96 9.79h-26.32M103.84 36.35s13.31 7.29 9.12 34.62"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M106.77 65.87s16.24 8.75 6.2 29.34m26.97 41.1H514v8.75H125.59m-39.72 0H66.43v-8.75h8.02m65.83 9.32V280.4m317.68-134.77V280.4M110.5 159.22s6.25 2.4 14.97 6.22m27.53-37.75s.67 7.25 11.49 3.75m255.44-92.6s1.86 8.58 8.73 7.72m-68.9 124.98s65.14 14.96 93.29-4.28M72.36 6.89c-.87 1.17-1.77 2.84-.45 5.59"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
