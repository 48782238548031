import "styles/Pages.css.ts.vanilla.css!=!../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"styles/Pages.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VXy5KjNhTd91coi6myq1ougcFgZpPPSFYpYWSsaUCMwG3PpPrfI4QAvdp2dzKVjU2J+77nXF027NiilIXg7ycAWlwUtClhz9oMRKi9fhWHPcdNd2S8hozTkjYZyFnfs9p4l4HugCuyCjZBvP769Pa0Ge1uXbthbNlddJGhG7m6SHsdy9cfig5t0vXwosZciI4m4V7GM5vdSbMF7doK/8gAbSraEJhX7PAyqOaMF4RDjgt67jIQBKpKOT68lJydmyIDr5ivIPwruORljH+G9XoOhvaUiRBxVQFRqA4Q3BHIzv1o+Qq7Ey7YJQMNa4geU3Zir4TLyBZH8MAqxm13Qbu2rYkQgUhSVh7wMser6Bnsn0Ei/pDVsEQ6+Xbuenr8ITw0PWn6DBzEL+GDYa/TcKyr6pUoy154mkpzoUV/En1PkVHoREvKb7TWA0vvCE6tEY6E745VtLgl9qEO2tkl72Z3t89LPnpP70Q+9fSx1j9QzP3nQH4UcIAXQsuTgMQOofmsoz+JkEtHsVtN+gQNlhonJoL2/0MJP88s/CjSc5WQ6WboAYgGP1A+Ot5CL7rNih2kadnsk2pjuDMkjlJCFTyNXy/WIJWPFe7Jn6tte9XDLh/qg5+E052gBvM4wsUxMo4rchQB43PPtEM+pjGdziQNZdGCoTnyyZzyJxlsja9wxpbxntrvrYn87RfdEgbDYpdhu1sMSz/PMA8kZhQECH2Rhsm1h7iiZbPcB0tBPn5F/QqSveg7wwh/gP5lxRbjH7uxKhtDUWzgWWSmYDur1OZOY9BtE8acGA6aMVk2x54Lxp172VC528Rj4xRF0PtUhkJSt8z+Ay7fZuKMLrGYHVYDxAAEW2SOlFYfRlapvtvVDaZZ93tNCorFyscJaQBuCrCqxaBQYslO3FJrqbzRd19r00xUq7x76lbm/baYiHwmpo1Ak4uVnLGABuH7vtAmsX0lpq+pvvIndTymtvQgo6R3jnQxxzeXVVzzU3Q2bA3Vo1Kd1NTVoUmUrvF4MT4HuE0VSMan0K3iyTU0zTFNirpSW+QaY2bc2yR2IpphOz4tEd3D2n4fWlhDyttELdmIkVucFKPfaQwHA4DAb7RuGe9x01uBB2bgUfzFEvDiGllA83w2eSEfrpd58YeYF+Plb/jbKn/+DztDPY1ddS+H0AMEslPSv/YWryuxNol6PgOopoyXVMasMXmz84Mxva8ZDZouNfd+TU0iN9AyT9jYjcJDXA8liJKSlzjsSHXMwLEiV0ia4jadl7R8n/xrdy28BbLxQnp3Omi7HtIHD9TvsdtEjzxErwwHrpnWzDhADqG+u47C3dzXt38A/x431UsRAAA=\"}!../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var btnOptions = 'ofp08oi';
export var chiSiamoImg = 'ofp08of';
export var chiSiamoImgWrapper = 'ofp08oe';
export var ctaSinistri = 'ofp08ol';
export var downloadBox = 'ofp08oo';
export var downloadIcon = 'ofp08on';
export var faqImage = 'ofp08oq';
export var fixArrows = 'ofp08om';
export var heroButton = 'ofp08o6';
export var heroButtonColorAuto = 'ofp08o7';
export var heroButtonColorMoto = 'ofp08o8';
export var heroButtonMoto = 'ofp08o9';
export var heroButtonSinistri = 'ofp08oj';
export var heroButtonSinistriLabel = 'ofp08ok';
export var heroImage = 'ofp08o2';
export var heroImageAlt = 'ofp08o3';
export var heroImageContact = 'ofp08o5';
export var heroImageWrapper = 'ofp08o1';
export var maxWidth = 'ofp08od';
export var noPadding = 'ofp08o4';
export var otherBlue = 'ofp08oa';
export var phoneIcon = 'ofp08oh';
export var phoneInfo = 'ofp08og';
export var shadowWrapper = 'ofp08ob';
export var smallHero = 'ofp08o0';
export var specialLineHeight = 'ofp08oc';
export var specialWidth = 'ofp08op';