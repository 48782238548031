import { Box, BoxProps } from '@facile-it/full-metal-ui/components'
import * as React from 'react'
export interface SpecWrapperProps extends BoxProps {
    ContentProps?: BoxProps
}

export const SpecWrapper: React.FC<SpecWrapperProps> = ({ children, ContentProps, ...boxprops }) => (
    <Box {...boxprops}>
        <Box
            className={ContentProps?.className}
            display="flex"
            justifyContent="center"
            position="relative"
            zIndex={1}
            {...ContentProps}
        >
            {children}
        </Box>
    </Box>
)
