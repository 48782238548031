import { Box } from '@facile-it/full-metal-ui/components'
import * as React from 'react'
import * as styles from './Layout.css'
import { Footer } from './footer'
import { Header } from './header'

export interface LayoutProps {
    children: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({ children }) => (
    <>
        <style jsx global>{`
            #onetrust-consent-sdk #onetrust-banner-sdk {
                font-family: 'Roboto' !important;
            }
            #onetrust-consent-sdk #onetrust-banner-sdk :not(.ot-leg-btn-container) > button:not(.ot-link-btn):hover {
                color: #fff !important;
            }
        `}</style>
        <main className={styles.layout}>
            <Header />
            <Box className={styles.childrenWrapper}>{children}</Box>
            <Footer />
        </main>
    </>
)
