import { Box, BoxProps } from '@facile-it/full-metal-ui/components'
import { Container } from '@facile-it/full-metal-ui/components/structural/FlexView'
import * as React from 'react'
import * as styles from './HeroContainer.css'

export type HeroVariant = 'small-one' | 'mid-one' | 'big-one'
export interface HeroContainerProps extends BoxProps {
    backgroundChildren?: React.ReactNode
    hasBackground?: boolean
    variant?: HeroVariant
}

export const HeroContainer: React.FC<HeroContainerProps> = ({
    backgroundChildren,
    children,
    hasBackground,
    variant,
    ...props
}) => (
    <Box
        className={[
            styles.container,
            { [styles.containerWithBackground]: hasBackground },
            variant === 'small-one' ? styles.containerWithBackgroundSmall : '',
            variant === 'mid-one' ? styles.containerWithBackgroundMid : '',
            variant === 'big-one' ? styles.containerWithBackgroundOversize : '',
        ]}
    >
        {backgroundChildren}
        <Container className={styles.inner}>
            <Box
                alignItems={{
                    mobile: 'center',
                    desktop: 'flexEnd',
                }}
                display="flex"
                justifyContent={{
                    mobile: 'spaceBetween',
                    desktop: 'flexStart',
                }}
                flexDirection={{
                    mobile: 'columnReverse',
                    desktop: 'row',
                }}
                height="full"
                width="full"
                position="relative"
                {...props}
            >
                {children}
            </Box>
        </Container>
    </Box>
)
