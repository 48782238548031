import { Box, Hidden, TextPreset } from '@facile-it/full-metal-ui/components'
import Link from 'next/link'
import * as React from 'react'
import { IconSVG } from '../../../components'
import { routes } from '../../../routes'
import { FooterLogo } from '../../branding'
import { AppLink } from '../../routing/AppLink'
import { Container } from '../Container'
import { Links } from '../header/Links'
import * as styles from './Footer.css'

export interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => (
    <Box className={styles.wrapper}>
        <Container className={styles.container}>
            <div>
                <Link href={routes.root} passHref>
                    <a className={styles.logoWrapper} title="Saturno homepage">
                        <FooterLogo />
                    </a>
                </Link>
            </div>
            <Box
                display="flex"
                alignItems={{ mobile: 'flexStart', desktop: 'center' }}
                flexDirection={{ mobile: 'column', desktop: 'row' }}
            >
                <Links className={styles.link} footer />
                <a className={styles.reservedArea} href={routes.personalarea}>
                    <IconSVG variant="user" alt="user" />
                    <TextPreset preset="paragraph-l" marginLeft="xs">
                        Area Riservata
                    </TextPreset>
                </a>
            </Box>
            <hr className={styles.hr} />
            <TextPreset preset="paragraph-m" className={styles.footerInfo}>
                Saturno è un prodotto di Wakam S.A., compagnia assicurativa iscritta al Registro delle imprese di
                Assicurazione in Italia con il N° II.01133.
            </TextPreset>
            <Hidden below="tablet">
                <TextPreset preset="paragraph-m" marginTop="m" className={styles.footerInfo}>
                    Il dominio www.saturno.it è nella titolarità di Brokerhero s.r.l. con socio unico, Largo Francesco
                    Richini, 6 - 20122, Milano
                </TextPreset>
                <TextPreset preset="paragraph-m" marginTop="xxs" className={styles.footerInfo}>
                    P.IVA 11426320963, intermediario soggetto alla vigilanza dell&apos;IVASS ed iscritto al RUI con
                    numero B000670603.
                </TextPreset>
                <TextPreset preset="paragraph-m" marginTop="xxs" className={styles.footerInfo}>
                    Telefono: <AppLink href="tel:+390258215030">02 58 21 50 30</AppLink>. PEC:{' '}
                    <AppLink href="mailto:brokerhero@legalmail.it">brokerhero@legalmail.it</AppLink>.
                </TextPreset>
            </Hidden>
            <Hidden above="mobileXl">
                <TextPreset preset="paragraph-m" marginTop="m" className={styles.footerInfo}>
                    Il dominio www.saturno.it è nella titolarità di Brokerhero s.r.l. con socio unico, Largo Francesco
                    Richini, 6 - 20122, Milano P.IVA 11426320963, intermediario soggetto alla vigilanza dell&apos;IVASS
                    ed iscritto al RUI con numero B000670603.
                </TextPreset>
                <TextPreset preset="paragraph-m" marginTop="m" className={styles.footerInfo}>
                    Telefono: <AppLink href="tel:+390258215030">02 58 21 50 30</AppLink>. PEC:{' '}
                    <AppLink href="mailto:brokerhero@legalmail.it">brokerhero@legalmail.it</AppLink>.
                </TextPreset>
            </Hidden>
        </Container>
    </Box>
)
