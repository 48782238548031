import { Box, TextPreset } from '@facile-it/full-metal-ui/components'
import clsx from 'clsx'
import * as React from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import * as styles from './Panel.css'

export interface PanelProps {
    open?: boolean
    title: React.ReactNode
    children: React.ReactNode
}

export const Panel: React.FC<PanelProps> = ({ open = false, title, children }) => {
    const [toggled, settoggled] = React.useState(open)

    return (
        <Box className={styles.container} onClick={() => settoggled(!toggled)}>
            <Box position="relative" display="flex">
                <TextPreset
                    className={styles.title}
                    preset="paragraph-xl"
                    fontWeight="bold"
                    paddingRight={{ mobile: 'm', desktop: 'none' }}
                >
                    {title}
                </TextPreset>
                <AiOutlinePlus className={clsx(styles.icon, toggled && styles.icontoggled)} />
            </Box>
            <Box className={clsx(styles.contentwrapper, toggled && styles.contentwrappertoggled)}>
                <TextPreset
                    className={clsx(styles.content, toggled && styles.contenttoggled)}
                    preset="paragraph-xl"
                    paddingRight={{ mobile: 'none', desktop: 'l' }}
                >
                    {children}
                </TextPreset>
            </Box>
        </Box>
    )
}
