import { useRouter } from 'next/router'
import * as React from 'react'
import { routes } from '../../../routes'
import { AppLink } from '../../routing/AppLink'

declare const OneTrust: {
    ToggleInfoDisplay: () => any
}
export interface LinksProps {
    className?: string
    footer?: boolean
}

export const Links: React.FC<LinksProps> = ({ className, footer }) => {
    const router = useRouter()
    const iscurrent = (route: string) => route === router.asPath

    const clickHandler = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault()
        OneTrust.ToggleInfoDisplay()
    }

    return (
        <>
            <AppLink
                className={className}
                footer={footer}
                current={iscurrent(routes.assicurazioni)}
                href={routes.assicurazioni}
            >
                Assicurazioni
            </AppLink>
            <AppLink
                className={className}
                footer={footer}
                current={iscurrent(routes.gestionesinistri)}
                href={routes.gestionesinistri}
            >
                Gestione sinistri
            </AppLink>
            <AppLink className={className} footer={footer} current={iscurrent(routes.reclami)} href={routes.reclami}>
                Reclami
            </AppLink>
            <AppLink
                className={className}
                footer={footer}
                current={iscurrent(routes.setinformativi)}
                href={routes.setinformativi}
            >
                Set informativi
            </AppLink>
            <AppLink className={className} footer={footer} current={iscurrent(routes.chisiamo)} href={routes.chisiamo}>
                Chi siamo
            </AppLink>
            <AppLink className={className} footer={footer} current={iscurrent(routes.contatti)} href={routes.contatti}>
                Contatti
            </AppLink>
            {footer && (
                <>
                    <AppLink
                        className={className}
                        footer={footer}
                        current={iscurrent(routes.privacyPolicy)}
                        href={routes.privacyPolicy}
                    >
                        Privacy Policy
                    </AppLink>
                    <AppLink className={className} footer={footer} href="#" onClick={clickHandler}>
                        Gestione Cookie
                    </AppLink>
                </>
            )}
        </>
    )
}
