import * as React from 'react'
import { getIconImgSrc, getIconSrc, IconVariant } from './get-icon-src'

export interface IconProps
    extends Partial<Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src'>> {
    variant: IconVariant
}

export const Icon: React.FC<IconProps> = ({ variant, ...imgprops }) => {
    const { src } = getIconImgSrc(variant)

    // eslint-disable-next-line @next/next/no-img-element
    return <img src={src} {...imgprops} alt={imgprops.alt ? imgprops.alt : ''} />
}

export const IconSVG: React.FC<IconProps> = ({ variant }) => <>{getIconSrc(variant)}</>
