const assicurazioni = `/assicurazioni`
const chisiamo = `/chi-siamo`
const contatti = `/contatti`
const gestionesinistri = `/gestione-sinistri`
const privacyPolicy = `/privacy-policy`
const reclami = `/reclami`
const root = `/`
const setinformativi = `/set-informativi`
const preventivoAuto = `https://www.facile.it/assicurazioni-auto/preventivo.html`
const preventivoMoto = `https://www.facile.it/assicurazioni-moto/preventivo.html`

//#region personal links

const personalarea = `https://homeinsurance-saturno.siaspa.com/homeinsurance/`

//#endregion

export const routes = {
    assicurazioni,
    chisiamo,
    contatti,
    gestionesinistri,
    privacyPolicy,
    personalarea,
    reclami,
    root,
    setinformativi,
    preventivoAuto: preventivoAuto,
    preventivoMoto: preventivoMoto,
}
