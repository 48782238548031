import * as React from 'react'

export const Handshaking: React.FC<{ className?: string }> = ({ className }) => (
    <svg
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 542 355"
        width="100%"
        height="100%"
        role="img"
    >
        <title id="gestione">Gestione Sinistri</title>
        <path
            d="M479.28 76.6a15.29 15.29 0 0 0-7.09-3.2c-8.35-1.44-16.25 6.1-17.7 14.46l84.82-.04a12.83 12.83 0 0 0-5.24-12.63 12.84 12.84 0 0 0-13.64-.76"
            fill="#fff"
        />
        <path
            d="M479.27 76.63a21.08 21.08 0 0 1 19.6-17.5 21.09 21.09 0 0 1 21.44 15.21m-365.12-44.2A26.3 26.3 0 0 0 143 24.6c-14.38-2.46-27.97 10.52-30.43 24.9l145.98-.08a22.1 22.1 0 0 0-32.5-23.05"
            fill="#fff"
        />
        <path d="M155.15 30.17a36.3 36.3 0 0 1 70.64-3.94" fill="#fff" />
        <path
            d="M476.01 293.84h-9.12a30.93 30.93 0 0 0-.06 1.89c0 16.25 12.65 29.43 28.25 29.43s28.25-13.18 28.25-29.43c0-.68-.02-1.35-.06-2.02l-8.27.02v4.58h-38.79s-.45-4.47-.2-4.47Z"
            fill="#FFF6DB"
        />
        <path
            d="M285.99 293.84h-9.13a30.15 30.15 0 0 0-.06 1.89c0 16.25 12.65 29.43 28.25 29.43s28.26-13.18 28.26-29.43c0-.68-.03-1.35-.07-2.02l-8.26.02-39 .11Z"
            fill="#F5F2D8"
        />
        <path
            d="M531.31 279.43c5.2 0 9.41-7.53 9.41-16.81 0-9.29-4.21-16.82-9.4-16.82-5.2 0-9.42 7.53-9.42 16.82 0 9.28 4.22 16.81 9.41 16.81Zm-81.5 0c5.2 0 9.41-7.53 9.41-16.81 0-9.29-4.21-16.82-9.4-16.82-5.2 0-9.42 7.53-9.42 16.82 0 9.28 4.22 16.81 9.41 16.81Z"
            fill="#FFD05A"
        />
        <path d="m256.53 223.62-3.6-14.19a6.85 6.85 0 0 1 4.94-8.3 6.85 6.85 0 0 1 8.3 4.95l4.5 17.7" fill="#F5F2D8" />
        <path
            d="m270.67 223.78-4.5-17.7a6.85 6.85 0 0 0-8.3-4.95 6.86 6.86 0 0 0-4.18 3.14 6.86 6.86 0 0 0-.76 5.17l3.6 14.18"
            fill="#FFF6DB"
        />
        <path d="m202.99 223.62-3.6-14.19a6.85 6.85 0 0 1 4.94-8.3 6.85 6.85 0 0 1 8.3 4.95l4.5 17.7" fill="#F5F2D8" />
        <path d="m202.99 223.62-3.6-14.19a6.85 6.85 0 0 1 4.94-8.3 6.85 6.85 0 0 1 8.3 4.95l4.5 17.7" fill="#FFF6DB" />
        <path d="M170.96 247.75h10.07a6.64 6.64 0 0 0 6.62-6.61V224.3h-16.71l.02 23.45Z" fill="#FFD05A" />
        <path
            d="m538.61 274.08-8.04-28.82c-12.37-.48-20.41 5.29-20.41 5.29l-16.56-7.04-3.94-1.67-42.54-18.06h-4.68l-13.82-31.7h-54l-.15.63h-47.81l9.89 31.45h-6.09v-9.35a1.76 1.76 0 0 0-1.76-1.75h-25.12a1.76 1.76 0 0 0-1.76 1.75v9.34h-30.38v.07H170.96v69.79h27.37l-.24 1.08.6 6.72 1.33 4.2 2.63 5.42 3.84 5.04 4.69 3.96 6.24 3.02 6.6 1.8 10.93.12 8.89-1.69 6.24-2.52 6.6-3.84 4.32-4.08 4.08-6.25 2.88-6.6 1.33-6.47 88.95.48-.24 3.12 1.08 6.96 2.16 4.8 5.18 7.34 6.24 4.8 8.77 3.13 5.76.72 6.85.23 9.36-1.43 7.08-2.29 8.3-5.64 5.17-5.76s3.72-8.04 4.44-9.13c.72-1.08 1.44-7.2 1.44-7.2l46.2-.17.2 4.5h38.8v-4.63l23.74-.1-.16-19.57Z"
            fill="#fff"
        />
        <path
            d="M531.17 279.69c5.18 0 9.37-7.5 9.37-16.75s-4.2-16.75-9.37-16.75c-5.18 0-9.38 7.5-9.38 16.75s4.2 16.75 9.38 16.75Zm-81.2 0c5.18 0 9.38-7.5 9.38-16.75s-4.2-16.75-9.38-16.75c-5.17 0-9.37 7.5-9.37 16.75s4.2 16.75 9.37 16.75Z"
            fill="#FFD05A"
        />
        <path
            d="m256.53 223.62-3.6-14.19a6.85 6.85 0 0 1 4.94-8.3v0a6.85 6.85 0 0 1 8.3 4.95l4.5 17.7m244.5 70.08h23.5v-20.39m-118.34 20.39h55.64m-26.16-14.43c5.2 0 9.41-7.53 9.41-16.81 0-9.29-4.21-16.82-9.4-16.82-5.2 0-9.42 7.53-9.42 16.82 0 9.28 4.22 16.81 9.41 16.81Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M448.83 279.43c-6.3 0-11.41-7.53-11.41-16.82 0-9.28 5.1-16.8 11.41-16.8m82.48 33.62c5.2 0 9.41-7.53 9.41-16.81 0-9.29-4.21-16.82-9.4-16.82-5.2 0-9.42 7.53-9.42 16.82 0 9.28 4.22 16.81 9.41 16.81Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M530.34 279.43c-6.31 0-11.42-7.53-11.42-16.82 0-9.28 5.11-16.8 11.42-16.8"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M510.16 250.43a30.88 30.88 0 0 1 10.8-4.27 29.1 29.1 0 0 1 10.36-.34m-37.94-2.4 20.6 8.67m-67.06-28.26 43.11 18.1m24.98 42.5h-38.78v14.02h38.78v-14.02ZM187.65 224.3v16.84a6.64 6.64 0 0 1-6.62 6.61h-10.07m88.98 46.12h98.19"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M170.94 224.32v69.54h27.17m134.32-69.54 90.94 29.8c3.15-3.66 8.64-6.72 15.29-8.07a31.82 31.82 0 0 1 11.84-.25m-164.88-1.18h-15.17v3.71h15.17v-3.71Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M304.08 293.85h-17.87a22.6 22.6 0 0 1-22.55-22.54v-47m195.56 38.3h59.7m-72-38.78-116.24.4m-74.15-.61-3.6-14.19a6.85 6.85 0 0 1 4.94-8.3v0a6.85 6.85 0 0 1 8.3 4.95l4.5 17.7m-67.68-.16-3.6-14.19a6.85 6.85 0 0 1 4.94-8.3v0a6.85 6.85 0 0 1 8.3 4.95l4.5 17.7"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m202.99 223.62-3.6-14.19a6.85 6.85 0 0 1 4.94-8.3v0a6.85 6.85 0 0 1 8.3 4.95l4.5 17.7m225.17.08-13.52-31.55H326.69l10.35 31.56m194.27 55.56c5.2 0 9.41-7.53 9.41-16.81 0-9.29-4.21-16.82-9.4-16.82-5.2 0-9.42 7.53-9.42 16.82 0 9.28 4.22 16.81 9.41 16.81Zm-81.5 0c5.2 0 9.41-7.53 9.41-16.81 0-9.29-4.21-16.82-9.4-16.82-5.2 0-9.42 7.53-9.42 16.82 0 9.28 4.22 16.81 9.41 16.81Zm-148.6-55.6-130.27.5m55.72 100.85c40.83 1.53 42.59-31.12 42.59-31.12"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M226.66 325.18c15.6 0 28.25-13.18 28.25-29.43s-12.65-29.43-28.25-29.43-28.24 13.18-28.24 29.43 12.64 29.43 28.24 29.43Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M259.94 293.63s-3.1-37.36-35.66-36.35c-30.58.94-34.86 30.56-33.68 36.57h7.5"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M386.8 305.27c5.29 0 9.58-4.94 9.58-11.03 0-6.1-4.3-11.04-9.59-11.04-5.3 0-9.59 4.94-9.59 11.04 0 6.09 4.3 11.03 9.6 11.03Z"
            fill="#FFF6DB"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M385.66 325.25c41.6 2.99 43.97-31.43 43.97-31.43"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M386.68 325.18c15.6 0 28.25-13.18 28.25-29.43s-12.64-29.43-28.25-29.43c-15.6 0-28.24 13.18-28.24 29.43s12.64 29.43 28.24 29.43Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M420.12 293.89s-3.26-37.62-35.84-36.61c-30.57.94-34.86 30.56-33.68 36.57h7.5m108.79.01a30.18 30.18 0 0 0-.06 1.89c0 16.25 12.65 29.43 28.25 29.43s28.25-13.18 28.25-29.43c0-.68-.02-1.05-.06-1.73m-237.28-.18h-9.13a30.15 30.15 0 0 0-.06 1.89c0 16.25 12.65 29.43 28.25 29.43s28.26-13.18 28.26-29.43c0-.68-.03-1.35-.07-2.02l-8.26.02-39 .11Zm43.28-61.86h-25.7a2.13 2.13 0 0 1-2.13-2.13V215.1a2.13 2.13 0 0 1 2.13-2.13h24.84a2.13 2.13 0 0 1 2.14 2.13v11.96"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M337.01 230.98c-.26-.06-4.27-2.5-6.6-3.88l-1.62-1m-2.46 4.65c.3.24 8.9 3.51 8.9 3.51"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M226.77 305.27c5.3 0 9.6-4.94 9.6-11.03 0-6.1-4.3-11.04-9.6-11.04s-9.59 4.94-9.59 11.04c0 6.09 4.3 11.03 9.6 11.03Z"
            fill="#FFF6DB"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m416.22 192.4-24.23 31.34m-30.25-31.42-24.46 31.4m87.01-31.4-24.15 31.7m103.1-33.31c-5.4-.98-11.39-2.6-16.78-1.6-5.4 1-10.25 5.12-11.82 10.37-1.3 4.35.2 9.22 3.14 12.7 2.92 3.48 7.99 5.16 12.3 6.61a42.56 42.56 0 0 1 10.46 4.86c3.4 2.24 5.9 6.58 4.42 10.36-1.77 4.55-7.72 5.57-11.28 8.9-4.88 4.57-4.32 12.32-3.41 18.95"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M487.53 262.18c-3.1-11.3-.6-16.69 1.85-19.4 1.69-1.88 4-3.08 6-4.63 2-1.55 3.83-3.68 3.96-6.2.14-2.66-1.68-5.1-3.94-6.49-2.26-1.39-4.93-1.88-7.53-2.36"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M153.14 61.51c9.5 4.54 12.9-7.51 12.9-7.51 7.1-4.54 1.42-9.36 1.42-9.36 36.02 10.8 43.23-31.74 17.3-35.59-29.63-4.4-25.38 14.6-50.62 7.94-17.5-4.62-23.07 4.6-24.85 10.69l-.14-.05c-1.94-2.52-7.17-7.68-11.07-7.44-3.66.24 2.32 5.77 6.65 8.76a47.3 47.3 0 0 0-4.11-1.68c-.61-.2-5.54-1.29-4.6.55.21.3.49.56.8.75a29.14 29.14 0 0 0 8.64 4.08c-11.08 2.33.89 22.12.89 22.12s6.9-.65 7.86 1.9c0 0 .77 12.4 6.69 10.92 4.52-1.13.56-15.26.56-15.26s10.55-6.88 30.23-4.43l1.45 13.61Z"
            fill="#FFD05A"
        />
        <path
            d="m151.97 49.7.75 17.09L151 80.26l-4.49 8.86-7.48 5.61-6.99 1-6.87-1.48-5.24 11.95 5.98 7.24 6.24 10.1 4.12 12.85 1.72 7.48 10.26 1.37 13.1 3.62 6.74 3.62 2 3-.13 6.23-.25 3.88 2.87 1.12 3.62-1.24 4.36 1.37 1.75-1.62 4.75-.87 7.98 1.5 2.37.13.5-7.14 1.5-3.24 2.74-.63 70.23-28.07 11.85-3.12 10.36-1h5.11l3.5-10.47-6.27-5.97-3.12-9.85.86-10.6 2.62-7.24 4.87-13.22 3.74-6.73-1.62-11.98-3-10.48-2.74-3.88 5.86-1.13 6.64 8.12-2.24-8.6 4.24.74 10.72.63 10.23-.13 6.99 1.87 5.99 3.89 3.5 5.23 2.36 5.24.37 3.12 6.74 4.24 4.12 4.62 1.37 5.1-.5 5.12-6.61 3-9.12 6.58-5.12 5.49 4.12 1.12 3.12 3-.13 3.11-.75 2.4-2.62 1.62-4.75.24-2.99-1.36-7.48 28.92 20.45 7.74s19.09 8.98 19.46 9.35c.38.37 16.22 11.85 16.22 11.85l10.6 13.35 2.5 8.85-.87 9.23-3.99 12.22-5.86 8.73-7.1 6.61-5.98 4.5-2.74-1.38-3.62 4.5s1.62 1.86 1.62 2.24c0 .37-.62 3.99-.62 3.99l-5.12 3.12-4.24-1-10.12-7.98-.5 118.24h-2l-74.1.36-.5-132.58-12.22-4.87 5.5-24.18-49.16 27.2-3.49 1.13-6.23-5.12-1.73-3.74-3.75.5-.86.75-.12 3.87-3.62.86-2.37-.75-.12 2.12-1.38 2-4 .12-.99 1.75-.5 2-2.74.62-3-1.73-2.36 2.5-2.12 1.36-2.78-1.4-.62-2.49-3.37.75-1.37-1.62-.5-2.5-3.24.5-2.25-1.37v-2.5l-3 .38-1.72-1.37.5-3.25s-7.84-5-7.11-4.61c.73.4-1.75 3-1.75 3l-4 3.88-3.24.12-5.86-1.37-9.97-2.51-1.4 13.45-8.35 1.38 27.2 116.5-43.42 10.86-22.82-98.66-11.86 91.68-42.28-3.88 15.34-127.85-3.99-3.12s2.12-22.57 3.62-30.68 9.73-39.91 11.35-42.9c1.62-3 12.35-28.94 45.15-28.82l12.46-29.3-4.61.62-4.62-2.24-1-3.62 2.12-3.89 2.91-1.3 5.08-.19 2.78 2.1 1.58 7.63 2.37 3.12 2.74.17 1.75-3.17-.36-7.71-.8-4.36 10.16-4.13 17.22-1.24 2.85.94.27 1.8Z"
            fill="#fff"
        />
        <path
            d="M306.07 58.03s-2.15-17.1-3.68-19.28a74.15 74.15 0 0 1-3.69-6.43l6.04-1.13 5.96 7.56-1.8-8.5s5.67 1.5 6.52 1.4c.85-.09 11.54.2 17.68.39 6.15.19 11.63 4.44 11.63 4.44s7.11 5.36 7.66 10.4l.54 5.03s6.74 2.53 8.64 5.55c1.9 3.03 6.14 6.15 3.6 12.48 0 0-15.32 6.72-20.43 15.11l-2.83 1.04-2.93 1.52-3.5-2.94s2.36-5.48 1.6-8.6c-.75-3.12-2.83-10.01-9.16-12.38-6.34-2.37-17.78-5.38-21.84-5.66Zm-5.91 31.86s1.36-2.09 3.26-2.09c1.9 0 8.6.45 11.5 1.81 2.9 1.36 7.41 2.09 8.68 4.89 1.27 2.8 2.8 3.53 2 4.7l-19.65-6.42-5.79-2.9Z"
            fill="#FFD05A"
        />
        <path
            d="M111.4 337.95v15.93h7.7v-5.21l5.86 5.25h49.9v-4.06s-29.6-3.72-33.18-18.8m-77.2.41v22.4h-7.73v-5.2l-5.86 5.25H1v-4.06s33.25-2.78 33.18-20.95m280.45 9.9v15.07h7.7v-5.21l5.86 5.25h49.9v-4.06s-20.26 1.07-27.8-10.95"
            fill="#FFF6DB"
        />
        <path
            d="M314.66 338.81v15.07h-7.7v-5.21l-5.86 5.25h-49.9v-4.06s20.26 1.07 27.8-10.95m40.06-196.23-4.86 5.18-10.63-1.62s-1.88-5.7-1.88-6.04l9.93-4.2 7.43 6.68Zm-15.5 3.58L289.55 176l10.21 23.78 16.3-21.27-1.85-30.63-10.63-1.62Z"
            fill="#FFF6DB"
        />
        <path
            d="M112.02 82.64s19.43 26.23 35.59 4.4c7.85-10.61 4.25-39.14 4.25-39.14"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M111.73 65.36c0 .44-12.9 30.32-12.9 30.32l20.91 17.17-.08-6.36m5.83-12.22-5.84 12.22m-5.22-49.37s.28 10.63 5.67 10.63 1.3-15.88 1.3-15.88 18.56-8.5 33.03-3.26M109.3 27.68c1.78-6.1 7.35-15.31 24.85-10.69 25.24 6.67 20.99-12.34 50.62-7.94 25.94 3.85 18.72 46.37-17.3 35.59m-61.1 10.13s-11.97-19.8-.89-22.12"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M167.46 44.64s5.67 4.82-1.42 9.36c0 0-3.4 12.05-12.9 7.51m-20.44 81.73s-14.34-35.34-37.66-38.26c-23.33-2.93-31.04 20.25-29.2 29.66 1.86 9.41 13.88 50.86 79.93 65.83"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M113.51 144.37s32.68-4.86 54.47 7.81"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M156.07 193.78c-4 5.1-7.86 7.77-10.3 6.68-4.18-1.92-2.62-14.28 3.48-27.64s14.44-22.6 18.62-20.7c2.59 1.2 2.97 6.38 1.44 13.33"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M180.2 166.9c-5.93-3.32-4.85.56-8.76-.63-4.97-1.5-6.83-3.15-6.83-3.15-3.25 3.7-6.7 9.16-9.64 15.55a76.17 76.17 0 0 0-4.1 11.1s3.88 3.6 11.45 7.5m35.07-31.73c-.38-5.37.52-9.15 2.76-9.99 4.31-1.6 11.94 8.24 17.06 22 5.11 13.74 5.77 26.18 1.47 27.79-2.67.99-6.18-1.98-10.01-7.97"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M208.49 197.01a59.4 59.4 0 0 1 4.75-1.73c-.32-4.92-1.73-11.22-4.19-17.81a76.18 76.18 0 0 0-4.94-10.75"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M204.11 166.7s-7-.31-11.97-1.38c-4.97-1.06-10.65-3.43-14.45 6.75-.75 2.03-2.13 4.43-2.16 6.63-.03 1.9 2.06 2.92 3.73 2.65 1.86-.3 3.16-2.49 4.23-3.95.72-.99 2.16-3.54 3.68-3.32 0 0 23.53 10.88 22.26 20.85-.95 7.46-15.97-4.75-15.97-4.75"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M203.98 197.06s1.9 6.86-3.45 5.68c-5.35-1.18-9.83-6.04-9.83-6.04"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M197.87 202.02s1.34 10.98-11.32-.16"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M191.81 205.83s.9 7.45-6.1 3.43m-18.65-16.76c.14 0 .29.01.43.04 1.54.27 2.88 1.47 2.71 3.15-.28 2.8-4.2 8.07-6.9 6.83-2.71-1.23-1.63-4.31.61-7.39.81-1.1 1.69-2.62 3.15-2.63Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M172.38 195.86c.14 0 .29.01.43.04 1.54.28 2.88 1.47 2.71 3.15-.27 2.8-4.2 8.07-6.88 6.84-2.69-1.23-1.63-4.32.62-7.4.79-1.1 1.66-2.62 3.12-2.63Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M177.76 199.28c.14 0 .29 0 .43.03 1.54.28 2.88 1.48 2.71 3.16-.28 2.8-4.2 8.06-6.9 6.83-2.71-1.23-1.63-4.31.61-7.39.81-1.1 1.7-2.62 3.15-2.63Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M183.25 202.53c.14 0 .29 0 .43.03 1.54.28 2.88 1.48 2.71 3.16-.28 2.8-4.2 8.07-6.9 6.83-2.71-1.23-1.63-4.32.61-7.4.81-1.1 1.69-2.61 3.15-2.63Zm16.9-46.97s70.42-29.84 83.57-31.83c11.89-1.8 16.21-.72 16.21-.72m-81.25 82.33 50.75-28.3m3.6-13.22-9.12 37.7s38.49 18.01 82.53 9.74"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m276.2 206.5.2 132.34h38.23V248.8m38.32-28.98v119.05h-38.32m-8.53-95.28 15.62 8.96M299.93 123l-5.43 19.43s17.79-6.65 17.32-6.8c-.46-.15-11.89-12.63-11.89-12.63Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m332.23 124.2-7.1 23.04s-13.83-11.4-13.35-11.4c.48-.01 20.45-11.64 20.45-11.64Zm-30.54 16.02c0 .35 1.88 6.05 1.88 6.05l10.63 1.61 4.86-5.18"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M303.57 146.26 289.54 176l10.21 23.78 16.3-21.27-1.85-30.63-10.63-1.62Zm29.44-45.9s-6.1 24.01-29.25 11.9c-23.15-12.1 2.42-54.04 2.42-54.04s-1.63-20.16-7.6-25.9c0 0 3.61-1.25 6.34-1.1l6.47 8.46-2.64-9.33s6.9 2.5 20.03 1.4 24.83 9.65 24.16 20.18c0 0 16.13 5.45 11.72 18.57 0 0-13.63 5.08-20.56 15.1"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M314.96 77.18s-7.77 14.17 1.34 11.66m-12.7 5.92s2.5 6.7 13.4 4.8m-10.82-41.34s39.32 1.8 29.16 26.54l3.45 2.66m18.94-43.1c-.3 4.28-4.79 7.6-4.79 7.6s4.65-1.92 7.89-.23m-58 60.49c.1.29-2.9 10.82-2.9 10.82m61.6 50.21-9.33 7.77s-1.9 23.5 26.09 35.76c0 0 20.56-11.4 23.31-35.76 4-35.26-69.24-56.24-69.24-56.24"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M352.6 189.3s-11.42 9.1-7.93 17.97c3.5 8.89 17.63 24.43 23.6 21.65 5.96-2.78 6.1-7.46 3.26-9.03m3.64-4.45-5.03 5.88m-55.51 117.5v15.06h7.7v-5.21l5.86 5.25h49.9v-4.06s-20.26 1.07-27.8-10.95"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M314.66 338.81v15.07h-7.7v-5.21l-5.86 5.25h-49.9v-4.06s20.26 1.07 27.8-10.95m-167.6-.96v15.93h7.7v-5.21l5.86 5.25h49.9v-4.06s-29.6-3.72-33.18-18.8m-77.2.41v22.4h-7.73v-5.2l-5.86 5.25H1v-4.06s33.25-2.78 33.18-20.95m103.7-184.93s-3.22-25.1-18.21-37.5M99.14 94.56s-36.42-3.52-48.2 37.64c-11.79 41.16-11.79 64.58-11.79 64.58s26.94 24.03 89.82 13.31l1.53-13.46m-9.9 15.43 27.96 116.63-44.08 10.03L81 235.16"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m43.2 200.23-15.58 127.2 42.34 4.47 11.83-93.12m27.36-211.15c-1.94-2.52-7.17-7.68-11.07-7.44-3.66.24 2.32 5.77 6.65 8.76m0 0a47.3 47.3 0 0 0-4.11-1.68c-.61-.2-5.54-1.29-4.6.55.21.3.49.56.8.75a29.14 29.14 0 0 0 8.64 4.08m8.99 24.27c-1.36-1.63-3.8-2.6-6.48-2.32-3.86.39-6.74 3.18-6.43 6.25.3 3.06 3.68 5.23 7.54 4.84a8.25 8.25 0 0 0 3.89-1.4"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M111.67 60.54s-2.78-2.2-4.37-2.47m2.13 1.35-1.77 1.52m230.92 27.3c1.35-1.63 3.8-2.6 6.48-2.32 3.86.39 6.74 3.18 6.43 6.25-.3 3.06-3.69 5.23-7.55 4.85a8.3 8.3 0 0 1-3.88-1.4l-7.83 28.58"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M341.36 91.86s2.77-2.2 4.36-2.47m-2.12 1.35 1.76 1.52"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M129.26 61c.2 0 .44.12.65.44.22.32.37.8.36 1.36a2.35 2.35 0 0 1-.42 1.34c-.23.32-.48.42-.67.41-.19 0-.43-.11-.65-.44-.21-.32-.37-.8-.35-1.36.01-.56.18-1.03.42-1.34.23-.31.48-.41.66-.4Zm17.19.1c.18.01.43.12.64.45.22.32.37.8.36 1.36a2.36 2.36 0 0 1-.42 1.34c-.23.31-.48.41-.67.41-.19 0-.43-.12-.65-.44-.21-.32-.37-.8-.35-1.36.01-.56.18-1.03.42-1.34.23-.32.47-.42.66-.41Z"
            fill="#061D53"
            stroke="#061D53"
            strokeWidth="1.5"
        />
        <path
            d="M137.93 66.66s6.12 4.31-.09 7.52m-.2 5.27a15.85 15.85 0 0 1-10.35-3.54"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M324.53 78.3c.18.07.37.25.49.62.1.37.11.87-.07 1.4-.18.53-.48.93-.8 1.16-.31.23-.58.25-.76.2-.18-.07-.37-.25-.49-.62-.1-.37-.11-.87.07-1.4.18-.53.48-.93.8-1.16.31-.23.58-.25.76-.2Zm-16.3-5.94c.18.06.37.24.48.62.12.37.12.87-.06 1.4-.18.53-.48.93-.8 1.16-.31.23-.58.25-.76.2-.18-.07-.37-.25-.49-.62-.1-.37-.11-.88.07-1.4.18-.54.48-.94.8-1.16.31-.23.58-.26.76-.2Z"
            fill="#061D53"
            stroke="#061D53"
            strokeWidth="1.5"
        />
        <path
            d="M300.02 90.43a4.04 4.04 0 0 1 3.62-2.47c2.7-.1 17.52 1.33 21.43 8.83 0 0 1.45 2.25.36 2.9l-25.4-9.26Z"
            stroke="#061D53"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
