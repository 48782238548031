import { Box, BoxProps } from '@facile-it/full-metal-ui/components'
import * as React from 'react'
import * as styles from './HeroTitle.css'

export interface HeroTitleProps extends Pick<BoxProps, 'fontWeight' | 'marginBottom'> {
    children: React.ReactNode
}

export const HeroTitle: React.FC<HeroTitleProps> = ({ children, ...props }) => (
    <Box
        className={styles.title}
        display="block"
        fontSize={{ mobile: 1.75, desktop: 3 }}
        fontWeight="bold"
        paddingBottom="l"
        {...props}
    >
        <h1 className={styles.noH1style}>{children}</h1>
    </Box>
)
