import { Box, BoxProps } from '@facile-it/full-metal-ui/components'
import * as React from 'react'
import * as styles from './HeroContent.css'

export interface HeroContentProps extends BoxProps {
    full?: boolean
}

export const HeroContent: React.FC<HeroContentProps> = ({ className, full, ...props }) => (
    <Box
        height={{
            mobile: '1/2',
            desktop: 'full',
        }}
        textAlign={{
            mobile: 'center',
            desktop: 'left',
        }}
        className={[styles.heroContent, { [styles.heroContentHalf]: !full }, className]}
        {...props}
    />
)
