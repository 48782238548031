import { Box, TextPreset } from '@facile-it/full-metal-ui/components'
import * as React from 'react'
import { IconSVG } from '../../../components'
import { routes } from '../../../routes'
import { HeaderLogo } from '../../branding'
import { AppLink } from '../../routing/AppLink'
import { Container } from '../Container'
import * as styles from './Desktop.css'
import { Links } from './Links'

export interface DesktopProps {}

export const Desktop: React.FC<DesktopProps> = () => (
    <div className={styles.container}>
        <Container>
            <Box
                alignItems="center"
                className={styles.header}
                display="flex"
                flexDirection="row"
                justifyContent="spaceBetween"
                paddingX="l"
            >
                <AppLink href={routes.root}>
                    <HeaderLogo />
                    <TextPreset preset="paragraph-s" position="absolute" className={styles.sublogo}>
                        Prodotto di Wakam S.A., creato per Brokerhero
                    </TextPreset>
                </AppLink>

                <Box as="nav" alignItems="center" display="flex" justifyContent="spaceBetween">
                    <Links className={styles.link} />
                    <a className={styles.reservedArea} href={routes.personalarea}>
                        <IconSVG variant="user" />
                        <TextPreset preset="paragraph-l" marginLeft="xs">
                            Area Riservata
                        </TextPreset>
                    </a>
                </Box>
            </Box>
        </Container>
    </div>
)
